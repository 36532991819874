let title = "FreeTaxUSA\u00AE"
const appYear = "2024"

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("../views/Home"),
		props: true,
		meta: {
			title:
				title +
				" - Free Tax Filing for All Federal Returns, E-File Income Taxes Online",
			canonical: "https://www.freetaxusa.com",
			metaTags: [
				{
					name: "description",
					content:
						"100% free federal tax filing. E-File your tax return directly to the IRS. Prepare federal and state income taxes online. " +
						appYear +
						" tax preparation software."
				},
				{
					name: "keywords",
					content:
						"free, tax, federal, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/:catchAll(.*)*",
		name: "PageNotFound",
		component: () => import("../views/PageNotFound"),
		meta: {
			title: title + " - Requested Page Not Found on",
			metaTags: [
				{
					name: "description",
					content: "Requested Page Not Found on"
				}
			]
		}
	},

	// alphabetic pages
	{
		path: "/1099",
		name: "1099",
		component: () => import("../views/1099"),
		props: true,
		meta: {
			title:
				"Free 1099-MISC Online Filing - How to E-File a 1099 Form on " + title,
			canonical: "https://www.freetaxusa.com/1099",
			metaTags: [
				{
					name: "description",
					content:
						"File your 1099 with the IRS for free. Free support for self-employed income, independent contractor, freelance, and other small business income."
				},
				{
					name: "keywords",
					content: "1099-misc,1099,file,e-file,online,free,filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/1099k",
		name: "1099k",
		component: () => import("../views/1099k"),
		props: true,
		meta: {
			title: "Free 1099-K Online Filing on " + title,
			canonical: "https://www.freetaxusa.com/1099k",
			metaTags: [
				{
					name: "description",
					content:
						"File your 1099k with the IRS for free. Easy to file forms from Venmo, Cashapp, and PayPal. Side hustle & freelance income supported."
				},
				{
					name: "keywords",
					content: "1099-k,1099,file,e-file,online,free,filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/1099r",
		name: "1099r",
		component: () => import("../views/1099r"),
		props: true,
		meta: {
			title:
				"File 1099-R Online Free - Federal Filing is Free Even With Retirement Income",
			canonical: "https://www.freetaxusa.com/1099r",
			metaTags: [
				{
					name: "description",
					content:
						"You can file a federal return with your retirement income for free. Distributions from pensions, annuities, retirement or profit-sharing plans, IRAs, and insurance contracts are all included."
				},
				{
					name: "keywords",
					content:
						"1099-r,retirement,income,filing,taxes,online,e-file,IRA,Pension,Distributions,CSA-1099-R,Civil,service,annuity,CSF-1099-R,survivor,RRB-1099-R,rail,road,board"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/1099-unemployment",
		name: "1099Unemployment",
		component: () => import("../views/1099Unemployment"),
		props: true,
		meta: {
			title: title + " - 1099 Unemployment",
			canonical: "https://www.freetaxusa.com/1099-unemployment",
			metaTags: [
				{
					name: "description",
					content:
						"Easy and quick to file unemployment income. E-file form 1099-G for no extra cost with FreeTaxUSA."
				},
				{
					name: "keywords",
					content:
						"1099-unemployment,1099,file,e-file,online,free,filing,unemployment"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/1099nec",
		name: "1099nec",
		component: () => import("../views/1099nec"),
		props: true,
		meta: {
			title: title + " - 1099nec",
			canonical: "https://www.freetaxusa.com/1099nec",
			metaTags: [
				{
					name: "description",
					content:
						"File your 1099-NEC with the IRS for free. Free support for self-employed income, independent contractor, freelance, and other small business income."
				},
				{
					name: "keywords",
					content: "keywords"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/1099c",
		name: "1099c",
		component: () => import("../views/1099c"),
		props: true,
		meta: {
			title: title + " - 1099c",
			canonical: "https://www.freetaxusa.com/1099c",
			metaTags: [
				{
					name: "description",
					content:
						"File your 1099-C with the IRS for free. Free support for self-employed income, independent contractor, freelance, and other small business income."
				},
				{
					name: "keywords",
					content: "keywords"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/1099b",
		name: "1099b",
		component: () => import("../views/1099b"),
		props: true,
		meta: {
			title: title + " - 1099b",
			canonical: "https://www.freetaxusa.com/1099b",
			metaTags: [
				{
					name: "description",
					content:
						"File your 1099-B with the IRS for free. Free support for self-employed income, independent contractor, freelance, and other small business income."
				},
				{
					name: "keywords",
					content: "keywords"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/1099g",
		name: "1099g",
		component: () => import("../views/1099g"),
		props: true,
		meta: {
			title: title + " - 1099g",
			canonical: "https://www.freetaxusa.com/1099g",
			metaTags: [
				{
					name: "description",
					content:
						"File your 1099-G with the IRS for free. Free support for self-employed income, independent contractor, freelance, and other small business income."
				},
				{
					name: "keywords",
					content: "keywords"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/1099int",
		name: "1099int",
		component: () => import("../views/1099int"),
		props: true,
		meta: {
			title: title + " - 1099int",
			canonical: "https://www.freetaxusa.com/1099int",
			metaTags: [
				{
					name: "description",
					content:
						"File your 1099-INT with the IRS for free. Free support for self-employed income, independent contractor, freelance, and other small business income."
				},
				{
					name: "keywords",
					content: "keywords"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/1099div",
		name: "1099div",
		component: () => import("../views/1099div"),
		props: true,
		meta: {
			title: title + " - 1099div",
			canonical: "https://www.freetaxusa.com/1099div",
			metaTags: [
				{
					name: "description",
					content:
						"File your 1099-DIV with the IRS for free. Free support for self-employed income, independent contractor, freelance, and other small business income."
				},
				{
					name: "keywords",
					content: "keywords"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/1099misc",
		name: "1099misc",
		component: () => import("../views/1099misc"),
		props: true,
		meta: {
			title: title + " - 1099misc",
			canonical: "https://www.freetaxusa.com/1099misc",
			metaTags: [
				{
					name: "description",
					content:
						"File your 1099-MISC with the IRS for free. Free support for self-employed income, independent contractor, freelance, and other small business income."
				},
				{
					name: "keywords",
					content: "keywords"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/ssa1099",
		name: "ssa1099",
		component: () => import("../views/ssa1099"),
		props: true,
		meta: {
			title: title + " - ssa1099",
			canonical: "https://www.freetaxusa.com/ssa1099",
			metaTags: [
				{
					name: "description",
					content:
						"File your SSA-1099 with the IRS for free. Free support for self-employed income, independent contractor, freelance, and other small business income."
				},
				{
					name: "keywords",
					content: "keywords"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/2017",
		name: "2017",
		component: () => import("../views/2017"),
		props: true,
		meta: {
			title: "File 2017 Federal Taxes (100% Free) on " + title,
			canonical: "https://www.freetaxusa.com/2017",
			metaTags: [
				{
					name: "description",
					content:
						"File previous year tax returns on FreeTaxUSA. Online software uses IRS and state 2017 tax rates and forms. 2017 tax deductions and write-offs are included to help you get a bigger refund."
				},
				{
					name: "keywords",
					content:
						"2017, tax, return, federal, state, income, file, online, free, late, old, back, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/2018",
		name: "2018",
		component: () => import("../views/2018"),
		props: true,
		meta: {
			title: "File 2018 Federal Taxes (100% Free) on " + title,
			canonical: "https://www.freetaxusa.com/2018",
			metaTags: [
				{
					name: "description",
					content:
						"File previous year tax returns on FreeTaxUSA. Online software uses IRS and state 2018 tax rates and forms. 2018 tax deductions and write-offs are included to help you get a bigger refund."
				},
				{
					name: "keywords",
					content:
						"2018, tax, return, federal, state, income, file, online, free, late, old, back, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/2019",
		name: "2019",
		component: () => import("../views/2019"),
		props: true,
		meta: {
			title: "File 2019 Federal Taxes (100% Free) on " + title,
			canonical: "https://www.freetaxusa.com/2019",
			metaTags: [
				{
					name: "description",
					content:
						"File previous year tax returns on FreeTaxUSA. Online software uses IRS and state 2019 tax rates and forms. 2019 tax deductions and write-offs are included to help you get a bigger refund."
				},
				{
					name: "keywords",
					content:
						"2019, tax, return, federal, state, income, file, online, free, late, old, back, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/2020",
		name: "2020",
		component: () => import("../views/2020"),
		props: true,
		meta: {
			title: "File 2020 Federal Taxes (100% Free) on " + title,
			canonical: "https://www.freetaxusa.com/2020",
			metaTags: [
				{
					name: "description",
					content:
						"File previous year tax returns on FreeTaxUSA. Online software uses IRS and state 2020 tax rates and forms. 2020 tax deductions and write-offs are included to help you get a bigger refund."
				},
				{
					name: "keywords",
					content:
						"2020, tax, return, federal, state, income, file, online, free, late, old, back, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/2021",
		name: "2021",
		component: () => import("../views/2021"),
		props: true,
		meta: {
			title: "File 2021 Federal Taxes (100% Free) on " + title,
			canonical: "https://www.freetaxusa.com/2021",
			metaTags: [
				{
					name: "description",
					content:
						"File previous year tax returns on FreeTaxUSA. Online software uses IRS and state 2021 tax rates and forms. 2021 tax deductions and write-offs are included to help you get a bigger refund."
				},
				{
					name: "keywords",
					content:
						"2021, tax, return, federal, state, income, file, online, free, late, old, back, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/2022",
		name: "2022",
		component: () => import("../views/2022"),
		props: true,
		meta: {
			title: "File 2022 Federal Taxes (100% Free) on " + title,
			canonical: "https://www.freetaxusa.com/2022",
			metaTags: [
				{
					name: "description",
					content:
						"File previous year tax returns on FreeTaxUSA. Online software uses IRS and state 2022 tax rates and forms. 2022 tax deductions and write-offs are included to help you get a bigger refund."
				},
				{
					name: "keywords",
					content:
						"2022, tax, return, federal, state, income, file, online, free, late, old, back, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/2023",
		name: "2023",
		component: () => import("../views/2023"),
		props: true,
		meta: {
			title: "File 2023 Federal Taxes (100% Free) on " + title,
			canonical: "https://www.freetaxusa.com/2023",
			metaTags: [
				{
					name: "description",
					content:
						"File previous year tax returns on FreeTaxUSA. Online software uses IRS and state 2023 tax rates and forms. 2023 tax deductions and write-offs are included to help you get a bigger refund."
				},
				{
					name: "keywords",
					content:
						"2023, tax, return, federal, state, income, file, online, free, late, old, back, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/about",
		name: "About",
		component: () => import("../views/About"),
		props: true,
		meta: {
			title: "Information About " + title + " - Best Value for Your Money",
			canonical: "https://www.freetaxusa.com/about",
			metaTags: [
				{
					name: "description",
					content:
						"FreeTaxUSA: America's trusted online tax preparation service. Founded in 2001, we offer free federal & low-cost state tax filing. Secure, easy-to-use, and backed by expert support. Save money & file your taxes with confidence."
				},
				{
					name: "keywords",
					content:
						"tax support income deduction refund supporting info about company federal efile returns"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/accessibility",
		name: "Accessibility",
		component: () => import("../views/Accessibility"),
		props: true,
		meta: {
			title: title + " - Accessibility",
			canonical: "https://www.freetaxusa.com/accessibility",
			metaTags: [
				{
					name: "description",
					content:
						"Accessible Tax Filing at FreeTaxUSA. We support standards for users with disabilities. Get help filing your taxes with features designed for everyone. Contact us for assistance."
				},
				{
					name: "keywords",
					content:
						"tax support income deduction refund supporting info about company federal efile returns"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/account-recovery",
		name: "AccountRecovery",
		component: () => import("../views/AccountRecovery"),
		props: true,
		meta: {
			title: "Sign In and Recover " + title + " Account",
			canonical: "https://www.freetaxusa.com/account-recovery",
			metaTags: [
				{
					name: "description",
					content:
						"Need help accessing your account? We'll help you recover your account with links to restore your password and username."
				},
				{
					name: "keywords",
					content: "forgot,password,username,locked,out,account,restore,access"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/affiliates",
		name: "Affiliates",
		component: () => import("../views/Affiliates"),
		props: true,
		meta: {
			title:
				title +
				" Affiliate Program - Make Money Marketing Our Online Tax Software",
			canonical: "https://www.freetaxusa.com/affiliates",
			metaTags: [
				{
					name: "description",
					content:
						"FreeTaxUSA affiliates receive 15% to 65% commissions on every sale! 90 return days cover the entire tax season."
				},
				{
					name: "keywords",
					content: "freetaxusa affiliate program marketing software tax online"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/amended",
		name: "Amended",
		component: () => import("../views/Amended"),
		props: true,
		meta: {
			title: title + " Amended Tax Return Filing - File Your 1040-X",
			canonical: "https://www.freetaxusa.com/amended",
			metaTags: [
				{
					name: "description",
					content:
						"Prepare and print your tax amendment online. File a 1040-X using our software."
				},
				{
					name: "keywords",
					content:
						"1040-X,form,amended,tax,return,irs,filing,instruction,how,online"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	//  answers pages
	{
		path: "/answers/1099-nec-1099-misc",
		name: "answers/1099Nec1099Misc",
		component: () => import("../views/answers/1099Nec1099Misc"),
		props: true,
		meta: {
			title: title + " - Form 1099-NEC and 1099-MISC",
			canonical: "https://www.freetaxusa.com/answers/1099-nec-1099-misc",
			metaTags: [
				{
					name: "description",
					content: "Form 1099-NEC and 1099-MISC"
				},
				{
					name: "keywords",
					content: "nec,form,1099,nonemployee,compensation,contractor,business"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/2120",
		name: "answers/2120",
		component: () => import("../views/answers/2120"),
		props: true,
		meta: {
			title: title + " - Multiple Support Declaration (Form 2120)",
			canonical: "https://www.freetaxusa.com/answers/2120",
			metaTags: [
				{
					name: "description",
					content: "Multiple Support Declaration (Form 2120)"
				},
				{
					name: "keywords",
					content: "form,2120,multiple,support,relative"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/amend",
		name: "answers/Amend",
		component: () => import("../views/answers/Amend"),
		props: true,
		meta: {
			title: title + " - Change or Amend My Tax Return",
			canonical: "https://www.freetaxusa.com/answers/amend",
			metaTags: [
				{
					name: "description",
					content: "Change or Amend My Tax Return"
				},
				{
					name: "keywords",
					content: "change,amend,tax,return"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/billing",
		name: "answers/Billing",
		component: () => import("../views/answers/Billing"),
		props: true,
		meta: {
			title: title + " - Billing",
			canonical: "https://www.freetaxusa.com/answers/billing",
			metaTags: [
				{
					name: "description",
					content: "Billing"
				},
				{
					name: "keywords",
					content: "Billing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/credits-deductions",
		name: "answers/CreditsDeductions",
		component: () => import("../views/answers/CreditsDeductions"),
		props: true,
		meta: {
			title: title + " - Credits and Deductions",
			canonical: "https://www.freetaxusa.com/answers/credits-deductions",
			metaTags: [
				{
					name: "description",
					content: "Credits and Deductions"
				},
				{
					name: "keywords",
					content: "billing,credit,card,declined,double,charge"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/dates",
		name: "answers/Dates",
		component: () => import("../views/answers/Dates"),
		props: true,
		meta: {
			title: title + " - Important Tax Dates",
			canonical: "https://www.freetaxusa.com/answers/dates",
			metaTags: [
				{
					name: "description",
					content: "Important Tax Dates"
				},
				{
					name: "keywords",
					content: "important,dates,deadline"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/deductions-itemized",
		name: "answers/DeductionsItemized",
		component: () => import("../views/answers/DeductionsItemized"),
		props: true,
		meta: {
			title: title + " - Deductions Itemized",
			canonical: "https://www.freetaxusa.com/answers/deductions-itemized",
			metaTags: [
				{
					name: "description",
					content: "Deductions Itemized"
				},
				{
					name: "keywords",
					content:
						"itemized,deductions,ira,donations,charities,medical,expenses"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/extension",
		name: "answers/Extension",
		component: () => import("../views/answers/Extension"),
		props: true,
		meta: {
			title:
				title +
				" Income Tax Extension, Federal Filing, E-File IRS Extension Online",
			canonical: "https://www.freetaxusa.com/answers/extension",
			metaTags: [
				{
					name: "description",
					content:
						"Free tax return extension filing. Easily file your state and federal tax extension forms online. Extend the deadline with fast IRS e-file."
				},
				{
					name: "keywords",
					content: "file,extension,delay"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers",
		name: "Answers",
		component: () => import("../views/Answers"),
		props: true,
		meta: {
			title: title + " - Answers",
			metaTags: [
				{
					name: "description",
					content: "Answers to your questions"
				},
				{
					name: "keywords",
					content: "answers, questions, faq"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/forgot-password",
		name: "answers/ForgotPassword",
		component: () => import("../views/answers/ForgotPassword"),
		props: true,
		meta: {
			title: title + " - Forgot My Password",
			canonical: "https://www.freetaxusa.com/answers/forgot-password",
			metaTags: [
				{
					name: "description",
					content: "Forgot My Password"
				},
				{
					name: "keywords",
					content: "forgot,password"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/forgot-username",
		name: "answers/ForgotUsername",
		component: () => import("../views/answers/ForgotUsername"),
		props: true,
		meta: {
			title: title + " - Forgot My Username",
			canonical: "https://www.freetaxusa.com/answers/forgot-username",
			metaTags: [
				{
					name: "description",
					content: "Forgot My Username"
				},
				{
					name: "keywords",
					content: "forgot,username"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/gambling",
		name: "answers/Gambling",
		component: () => import("../views/answers/Gambling"),
		props: true,
		meta: {
			title: title + " - Gambling Income",
			canonical: "https://www.freetaxusa.com/answers/gambling",
			metaTags: [
				{
					name: "description",
					content: "Gambling Income"
				},
				{
					name: "keywords",
					content: "gambling,income,w-2g"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/general-topics",
		name: "answers/GeneralTopics",
		component: () => import("../views/answers/GeneralTopics"),
		props: true,
		meta: {
			title: title + " - General Tax Topics",
			canonical: "https://www.freetaxusa.com/answers/general-topics",
			metaTags: [
				{
					name: "description",
					content: "General Tax Topics"
				},
				{
					name: "keywords",
					content:
						"tax,combat,pay,allocated,tips,home,sale,control,number,unemployment,social,security"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/hsa",
		name: "answers/Hsa",
		component: () => import("../views/answers/Hsa"),
		props: true,
		meta: {
			title: title + " - Health Savings Account (HSA)",
			canonical: "https://www.freetaxusa.com/answers/hsa",
			metaTags: [
				{
					name: "description",
					content: "Health Savings Account (HSA)"
				},
				{
					name: "keywords",
					content: "health,savings,account,hsa"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/investments",
		name: "answers/Investments",
		component: () => import("../views/answers/Investments"),
		props: true,
		meta: {
			title: title + " - Investment Income",
			canonical: "https://www.freetaxusa.com/answers/investments",
			metaTags: [
				{
					name: "description",
					content: "Investment Income"
				},
				{
					name: "keywords",
					content: "investment,dividends,income,1099-div,1099-int,stock"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/issues",
		name: "answers/Issues",
		component: () => import("../views/answers/Issues"),
		props: true,
		meta: {
			title: title + " - Top Issues",
			canonical: "https://www.freetaxusa.com/answers/issues",
			metaTags: [
				{
					name: "description",
					content: "Top Issues"
				},
				{
					name: "keywords",
					content: "troubleshooting,error,issues"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/pin",
		name: "answers/Pin",
		component: () => import("../views/answers/Pin"),
		props: true,
		meta: {
			title: title + " - How Do I Find My PIN",
			canonical: "https://www.freetaxusa.com/answers/pin",
			metaTags: [
				{
					name: "description",
					content: "How Do I Find My PIN"
				},
				{
					name: "keywords",
					content: "find,pin"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/print-issues",
		name: "answers/PrintIssues",
		component: () => import("../views/answers/PrintIssues"),
		props: true,
		meta: {
			title: title + " - Printing Issues",
			canonical: "https://www.freetaxusa.com/answers/print-issues",
			metaTags: [
				{
					name: "description",
					content: "Printing Issues"
				},
				{
					name: "keywords",
					content: "printing,pdf,issues"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/print-save",
		name: "answers/PrintSave",
		component: () => import("../views/answers/PrintSave"),
		props: true,
		meta: {
			title: title + " - How to Print or Save My Tax Return",
			canonical: "https://www.freetaxusa.com/answers/print-save",
			metaTags: [
				{
					name: "description",
					content: "How to Print or Save My Tax Return"
				},
				{
					name: "keywords",
					content: "print,save"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/prior-year",
		name: "answers/PriorYear",
		component: () => import("../views/answers/PriorYear"),
		props: true,
		meta: {
			title: title + " - Prior Year Tax Return",
			canonical: "https://www.freetaxusa.com/answers/prior-year",
			metaTags: [
				{
					name: "description",
					content: "Prior Year Tax Return"
				},
				{
					name: "keywords",
					content: "prior,year,return,past"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/refund",
		name: "answers/Refund",
		component: () => import("../views/answers/Refund"),
		props: true,
		meta: {
			title: title + " - Where is My Refund",
			canonical: "https://www.freetaxusa.com/answers/refund",
			metaTags: [
				{
					name: "description",
					content: "Where is My Refund"
				},
				{
					name: "keywords",
					content: "refund,irs,direct,deposit"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/retirement",
		name: "answers/Retirement",
		component: () => import("../views/answers/Retirement"),
		props: true,
		meta: {
			title: title + " - Retirement",
			canonical: "https://www.freetaxusa.com/answers/retirement",
			metaTags: [
				{
					name: "description",
					content: "Retirement"
				},
				{
					name: "keywords",
					content: "retirement"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/schedule-c",
		name: "answers/ScheduleC",
		component: () => import("../views/answers/ScheduleC"),
		props: true,
		meta: {
			title: title + " - Business Income (Schedule C)",
			canonical: "https://www.freetaxusa.com/answers/schedule-c",
			metaTags: [
				{
					name: "description",
					content: "Business Income (Schedule C)"
				},
				{
					name: "keywords",
					content:
						"schedule c,business,cash,accrual,method,mileage,medical,daycare,home,office"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/schedule-e",
		name: "answers/ScheduleE",
		component: () => import("../views/answers/ScheduleE"),
		props: true,
		meta: {
			title: title + " - Rental, Royalty, and K-1 Income (Schedule E)",
			canonical: "https://www.freetaxusa.com/answers/schedule-e",
			metaTags: [
				{
					name: "description",
					content: "Rental, Royalty, and K-1 Income (Schedule E)"
				},
				{
					name: "keywords",
					content: "chedule,e,rental,royalty,partner,passive,income"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/schedule-f",
		name: "answers/ScheduleF",
		component: () => import("../views/answers/ScheduleF"),
		props: true,
		meta: {
			title: title + " - Farm Income (Schedule F)",
			canonical: "https://www.freetaxusa.com/answers/schedule-f",
			metaTags: [
				{
					name: "description",
					content: "Farm Income (Schedule F)"
				},
				{
					name: "keywords",
					content: "schedule,f,farming,cash,accrual,method"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers/state-filing",
		name: "answers/StateFiling",
		component: () => import("../views/answers/StateFiling"),
		props: true,
		meta: {
			title: title + " - State Tax Filing",
			canonical: "https://www.freetaxusa.com/answers/state-filing",
			metaTags: [
				{
					name: "description",
					content: "State Tax Filing"
				},
				{
					name: "keywords",
					content: "state,filing,return"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers-search",
		name: "AnswersSearch",
		component: () => import("../views/AnswersSearch"),
		props: true,
		meta: {
			title: title + " FAQ Search",
			canonical: "https://www.freetaxusa.com/answers-search",
			metaTags: [
				{
					name: "description",
					content:
						"Find quick answers to your tax questions with FreeTaxUSA's comprehensive search. Explore our vast library of tax articles, FAQs, and expert advice to easily navigate the tax filing process."
				},
				{
					name: "keywords",
					content:
						"tax,support,income,deduction,refund,supporting,federal,technical,returns"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/audit",
		name: "Audit",
		component: () => import("../views/Audit.vue"),
		props: true,
		meta: {
			title: "Audit Defense: File with Protection and Peace of Mind",
			canonical: "https://www.freetaxusa.com/audit",
			metaTags: [
				{
					name: "description",
					content:
						"Get up to $1 million in services to defend you if you’re audited, including an audit defense team, IRS and state support, tax debt relief, and more."
				},
				{
					name: "keywords",
					content:
						"audit, audit defense, free, tax, federal, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/blog",
		name: "Blog",
		component: () => import("../views/Blog"),
		props: true,
		meta: {
			title:
				title +
				" FREE Tax Filing, Online Return Preparation, E-File Income Taxes",
			canonical: "https://www.freetaxusa.com/blog",
			metaTags: [
				{
					name: "description",
					content:
						"100% Free Tax Filing. Efile your tax return directly to the IRS. Prepare federal and state income taxes online."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state"
				},
				{
					name: "robots",
					content: "noindex, nofollow"
				}
			]
		}
	},
	{
		path: "/calculator",
		name: "Calculator",
		component: () => import("../views/Calculator"),
		props: true,
		meta: {
			title:
				title +
				" Federal & State Income Tax Calculator - Estimate your IRS Refund or Taxes Owed",
			canonical: "https://www.freetaxusa.com/calculator",
			metaTags: [
				{
					name: "description",
					content:
						"Best online tax calculator. Estimate your " +
						appYear +
						" taxes.  Fill in the step-by-step questions and your tax return is calculated."
				},
				{
					name: "keywords",
					content: "income, tax, calculator, estimator, irs, federal, state"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/calculator-brackets",
		name: "CalculatorBrackets",
		component: () => import("../views/CalculatorBrackets"),
		props: true,
		meta: {
			title:
				title +
				" Federal Income Tax Brackets - Estimate your Tax Bracket and IRS Taxes Owed",
			canonical: "https://www.freetaxusa.com/calculator-brackets",
			metaTags: [
				{
					name: "description",
					content: "Federal tax brackets and calculator, enter your taxable income to estimate your income tax."
				},
				{
					name: "keywords",
					content: "income, tax, calculator, estimator, irs, federal, state"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/credit/adoption",
		name: "Adoption",
		component: () => import("../views/credit/Adoption"),
		props: true,
		meta: {
			title: "Claim Your Adoption Credit on " + title,
			canonical: "https://www.freetaxusa.com/deduction",
			metaTags: [
				{
					name: "description",
					content:
						"Claim your adoption credit on FreeTaxUSA. Direct IRS e-file included. Free federal."
				},
				{
					name: "keywords",
					content: "adoption, credit, tax, filing, return, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/credit/child",
		name: "ChildTaxCredit",
		component: () => import("../views/credit/ChildTaxCredit"),
		props: true,
		meta: {
			title: "Claim Your Child Tax Credit on " + title,
			canonical: "https://www.freetaxusa.com/credit/child",
			metaTags: [
				{
					name: "description",
					content:
						"Child Tax Credit and Schedule 8812 Form are included. E-File directly to the IRS for free with dependents."
				},
				{
					name: "keywords",
					content: "child,credit,tax,filing,return,software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/credit/earned-income",
		name: "EarnedIncome",
		component: () => import("../views/credit/EarnedIncome.vue"),
		props: true,
		meta: {
			title: "Claim the EITC & Other Credits on " + title,
			canonical: "https://www.freetaxusa.com/credit/earned-income",
			metaTags: [
				{
					name: "description",
					content: "Claim the " + appYear + " Earned Income Tax Credit & more on FreeTaxUSA. Get your maximum refund. IRS e-file is included and federal is always free."
				},
				{
					name: "keywords",
					content: "recovery,rebate,credit,tax,filing,return,software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/credit/premium",
		name: "PremiumTaxCredit",
		component: () => import("../views/credit/PremiumTaxCredit"),
		props: true,
		meta: {
			title: "Claim Your Premium Tax Credit on " + title,
			canonical: "https://www.freetaxusa.com/credit/premium",
			metaTags: [
				{
					name: "description",
					content: "Premium Tax Credit and Schedule 8812 Form are included. E-File directly to the IRS for free with dependents."
				},
				{
					name: "keywords",
					content: "premium,credit,tax,filing,return,software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/credit/savers",
		name: "Savers",
		component: () => import("../views/credit/Savers"),
		props: true,
		meta: {
			title: "Claim Your Saver's Credit on " + title,
			canonical: "https://www.freetaxusa.com/deduction",
			metaTags: [
				{
					name: "description",
					content:
						"Claim your saver's credit on FreeTaxUSA. Direct IRS e-file included. Free federal."
				},
				{
					name: "keywords",
					content: "savers, credit, tax, filing, return, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/community-terms",
		name: "CommunityTerms",
		component: () => import("../views/CommunityTerms"),
		props: true,
		meta: {
			title: title + " Online Community Terms of Use",
			canonical: "https://www.freetaxusa.com/community-terms",
			metaTags: [
				{
					name: "description",
					content:
						"These Terms and Conditions of Use apply to the FreeTaxUSA Community website. By using the site, you agree to these terms of use."
				},
				{
					name: "keywords",
					content: "terms,conditions,use,service,community"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/contact",
		name: "Contact",
		component: () => import("../views/Contact"),
		props: true,
		meta: {
			title:
				"Contact " +
				title +
				" with Questions and Comments - Website, Company, & Affiliate Questions",
			canonical: "https://www.freetaxusa.com/contact",
			metaTags: [
				{
					name: "description",
					content:
						"We appreciate your feedback. We will respond as soon as possible. Please be advised that this response may be delayed by weekends and holidays."
				},
				{
					name: "keywords",
					content: "contact, us, freetaxusa"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/credit",
		name: "Credit",
		component: () => import("../views/Credit"),
		props: true,
		meta: {
			title: "Claim Your Tax Credits on " + title,
			canonical: "https://www.freetaxusa.com/deduction",
			metaTags: [
				{
					name: "description",
					content:
						"Claim Your " +
						appYear +
						" Tax Credits on FreeTaxUSA. IRS e-file is included and federal returns are always free."
				},
				{
					name: "keywords",
					content: "credit,tax,filing,return,software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/crypto",
		name: "Crypto",
		component: () => import("../views/Crypto"),
		props: true,
		meta: {
			title:
				"Crypto Tax Software - Report Investments on Your Taxes | " + title,
			canonical: "https://www.freetaxusa.com/crypto",
			metaTags: [
				{
					name: "description",
					content:
						"Generate tax Form 8949 on a crypto service and then prepare and e-file your taxes on FreeTaxUSA. Premium federal taxes are always free."
				},
				{
					name: "keywords",
					content:
						"tax service do free income tax preparation online prepare federal return returns e-file e-filing irs refund efile preparation electronic filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/deadline",
		name: "Deadline",
		component: () => import("../views/Deadline"),
		props: true,
		meta: {
			title: "Important Tax Dates & Deadlines | " + title,
			canonical: "https://www.freetaxusa.com/deadline",
			metaTags: [
				{
					name: "description",
					content:
						"Get the latest info on important tax dates here. FreeTaxUSA can help you file on time or file an extension. The best part, federal taxes are always free, and state taxes are $15 or less."
				},
				{
					name: "keywords",
					content: "deadline,due,date,filing,taxes,april"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/deceased",
		name: "Deceased",
		component: () => import("../views/Deceased"),
		props: true,
		meta: {
			title:
				title +
				" Free Federal Tax Filing for Deceased Relatives, Online Return Preparation, E-File Taxes",
			canonical: "https://www.freetaxusa.com/deceased",
			metaTags: [
				{
					name: "description",
					content:
						"Filing taxes for a deceased loved one? FreeTaxUSA guides you through the final tax return process. File federal & state returns online for free. Easy & secure for executors & personal representatives."
				},
				{
					name: "keywords",
					content:
						"free, deceased, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				}
			]
		}
	},
	{
		path: "/deduction",
		name: "Deduction",
		component: () => import("../views/Deduction"),
		props: true,
		meta: {
			title: "Claim Your Tax Deductions on " + title,
			canonical: "https://www.freetaxusa.com/deduction",
			metaTags: [
				{
					name: "description",
					content:
						"Claim your tax deductions on FreeTaxUSA. Direct IRS e-file included. Free federal."
				},
				{
					name: "keywords",
					content: "deduction, tax, filing, return, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/deduction/donation",
		name: "Donation",
		component: () => import("../views/deduction/Donation"),
		props: true,
		meta: {
			title: "Your Charitable Donation Deduction on " + title,
			canonical: "https://www.freetaxusa.com/deduction",
			metaTags: [
				{
					name: "description",
					content:
						"Claim your charitable donation deduction on FreeTaxUSA. Direct IRS e-file included. Free federal."
				},
				{
					name: "keywords",
					content:
						"charitable, donation, deduction, tax, filing, return, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/deduction/educator-expense",
		name: "EducatorExpense",
		component: () => import("../views/deduction/EducatorExpense"),
		props: true,
		meta: {
			title: "Claim Your Educator Expense Deduction on " + title,
			canonical: "https://www.freetaxusa.com/deduction",
			metaTags: [
				{
					name: "description",
					content:
						"Claim your educator expense deduction on FreeTaxUSA. Direct IRS e-file included. Free federal."
				},
				{
					name: "keywords",
					content:
						"educator, expense, deduction, tax, filing, return, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/deduction/gambling",
		name: "Gambling",
		component: () => import("../views/deduction/Gambling"),
		props: true,
		meta: {
			title: "Claim Your Gambling Loss Deduction on " + title,
			canonical: "https://www.freetaxusa.com/deduction",
			metaTags: [
				{
					name: "description",
					content:
						"Claim your gambling loss deduction on FreeTaxUSA. Direct IRS e-file included. Free federal."
				},
				{
					name: "keywords",
					content: "gambling, loss, deduction, tax, filing, return, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/deduction/home-office",
		name: "HomeOffice",
		component: () => import("../views/deduction/HomeOffice"),
		props: true,
		meta: {
			title: "Claim Your Home Office Deduction on " + title,
			canonical: "https://www.freetaxusa.com/deduction",
			metaTags: [
				{
					name: "description",
					content:
						"Claim your home office deduction on FreeTaxUSA. Direct IRS e-file included. Free federal."
				},
				{
					name: "keywords",
					content: "home, office, deduction, tax, filing, return, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/deduction/ira-contributions",
		name: "IraContributions",
		component: () => import("../views/deduction/IraContributions"),
		props: true,
		meta: {
			title: "Claim Your IRA Contributions Deduction on " + title,
			canonical: "https://www.freetaxusa.com/deduction",
			metaTags: [
				{
					name: "description",
					content:
						"Claim your IRA contributions deduction on FreeTaxUSA. Direct IRS e-file included. Free federal."
				},
				{
					name: "keywords",
					content:
						"IRA, contributions, deduction, tax, filing, return, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/deduction/medical",
		name: "Medical",
		component: () => import("../views/deduction/Medical"),
		props: true,
		meta: {
			title: "Claim Your Medical Expenses Deduction on " + title,
			canonical: "https://www.freetaxusa.com/deduction",
			metaTags: [
				{
					name: "description",
					content:
						"Claim your medical expenses deduction on FreeTaxUSA. Direct IRS e-file included. Free federal."
				},
				{
					name: "keywords",
					content:
						"medical, expenses, deduction, tax, filing, return, software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/deluxe",
		name: "Deluxe",
		component: () => import("../views/Deluxe"),
		props: true,
		meta: {
			title: title + " Best Tax Software - Deluxe Online Tax Program",
			canonical: "https://www.freetaxusa.com/deluxe",
			metaTags: [
				{
					name: "description",
					content:
						"The best tax service at the lowest price. Prepare your taxes using FreeTaxUSA Deluxe online version. Low cost income and business tax filing. Get the best!"
				},
				{
					name: "keywords",
					content:
						"tax service do free income tax preparation online prepare federal return returns e-file e-filing irs refund efile preparation electronic filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/download-forms",
		name: "DownloadForms",
		component: () => import("../views/DownloadForms"),
		props: true,
		meta: {
			title:
				"FREE " +
				appYear +
				" Printable Tax Forms from " +
				title +
				" - Download IRS Form Instructions Online",
			canonical: "https://www.freetaxusa.com/download-forms",
			metaTags: [
				{
					name: "description",
					content:
						"Get all your federal tax forms for free on " + title + ". Download individual forms or complete your federal tax return quickly and easily online."
				},
				{
					name: "keywords",
					content:
						"income tax, supported, forms, download, free federal, individual, tax schedule, irs, online preparation"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/download-forms-2017",
		name: "DownloadForms2017",
		component: () => import("../views/DownloadForms2017"),
		props: true,
		meta: {
			title:
				"FREE 2017 Printable Tax Forms from " +
				title +
				" - Download IRS Form Instructions Online",
			canonical: "https://www.freetaxusa.com/download-forms-2017",
			metaTags: [
				{
					name: "description",
					content:
						"Find the 2017 federal tax forms you need. Official IRS income tax forms are printable and can be downloaded for FREE. Get the current year income tax forms today!"
				},
				{
					name: "keywords",
					content:
						"2017, income tax, supported, forms, download, free federal, individual, tax schedule, irs, online preparation"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/download-forms-2018",
		name: "DownloadForms2018",
		component: () => import("../views/DownloadForms2018"),
		props: true,
		meta: {
			title:
				"FREE 2018 Printable Tax Forms from " +
				title +
				" - Download IRS Form Instructions Online",
			canonical: "https://www.freetaxusa.com/download-forms-2018",
			metaTags: [
				{
					name: "description",
					content:
						"Find the 2018 federal tax forms you need. Official IRS income tax forms are printable and can be downloaded for FREE. Get the current year income tax forms today!"
				},
				{
					name: "keywords",
					content:
						"2018, income tax, supported, forms, download, free federal, individual, tax schedule, irs, online preparation"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/download-forms-2019",
		name: "DownloadForms2019",
		component: () => import("../views/DownloadForms2019"),
		props: true,
		meta: {
			title:
				"FREE 2019 Printable Tax Forms from " +
				title +
				" - Download IRS Form Instructions Online",
			canonical: "https://www.freetaxusa.com/download-forms-2019",
			metaTags: [
				{
					name: "description",
					content:
						"Find the 2019 federal tax forms you need. Official IRS income tax forms are printable and can be downloaded for FREE. Get the current year income tax forms today!"
				},
				{
					name: "keywords",
					content:
						"2019, income tax, supported, forms, download, free federal, individual, tax schedule, irs, online preparation"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/download-forms-2020",
		name: "DownloadForms2020",
		component: () => import("../views/DownloadForms2020"),
		props: true,
		meta: {
			title:
				"FREE 2020 Printable Tax Forms from " +
				title +
				" - Download IRS Form Instructions Online",
			canonical: "https://www.freetaxusa.com/download-forms-2020",
			metaTags: [
				{
					name: "description",
					content:
						"Find the 2020 federal tax forms you need. Official IRS income tax forms are printable and can be downloaded for FREE. Get the current year income tax forms today!"
				},
				{
					name: "keywords",
					content:
						"2020, income tax, supported, forms, download, free federal, individual, tax schedule, irs, online preparation"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/download-forms-2021",
		name: "DownloadForms2021",
		component: () => import("../views/DownloadForms2021"),
		props: true,
		meta: {
			title:
				"FREE 2021 Printable Tax Forms from " +
				title +
				" - Download IRS Form Instructions Online",
			canonical: "https://www.freetaxusa.com/download-forms-2021",
			metaTags: [
				{
					name: "description",
					content:
						"Find the 2021 federal tax forms you need. Official IRS income tax forms are printable and can be downloaded for FREE. Get the current year income tax forms today!"
				},
				{
					name: "keywords",
					content:
						"2021, income tax, supported, forms, download, free federal, individual, tax schedule, irs, online preparation"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/download-forms-2022",
		name: "DownloadForms2022",
		component: () => import("../views/DownloadForms2022"),
		props: true,
		meta: {
			title:
				"FREE 2022 Printable Tax Forms from " +
				title +
				" - Download IRS Form Instructions Online",
			canonical: "https://www.freetaxusa.com/download-forms-2022",
			metaTags: [
				{
					name: "description",
					content:
						"Find the 2022 federal tax forms you need. Official IRS income tax forms are printable and can be downloaded for FREE. Get the current year income tax forms today!"
				},
				{
					name: "keywords",
					content:
						"2022, income tax, supported, forms, download, free federal, individual, tax schedule, irs, online preparation"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/download-forms-2023",
		name: "DownloadForms2023",
		component: () => import("../views/DownloadForms2023"),
		props: true,
		meta: {
			title:
				"FREE 2023 Printable Tax Forms from " +
				title +
				" - Download IRS Form Instructions Online",
			canonical: "https://www.freetaxusa.com/download-forms-2023",
			metaTags: [
				{
					name: "description",
					content:
						"Download the latest IRS tax forms for free. Get the 2023 federal tax forms you need to file your taxes with ease. Printable PDF forms for individual returns."
				},
				{
					name: "keywords",
					content:
						"2023, income tax, supported, forms, download, free federal, individual, tax schedule, irs, online preparation"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/email-removal",
		name: "EmailRemoval",
		component: () => import("../views/EmailRemoval"),
		props: true,
		meta: {
			title:
				"Unsubscribe from free tax e-filing information provided by " + title,
			canonical: "https://www.freetaxusa.com/email-removal",
			metaTags: [
				{
					name: "description",
					content:
						"Report a wrong email address to unsubscribe from future mailings"
				},
				{
					name: "keywords",
					content: "unsubscribe,wrong,email"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/email-security",
		name: "EmailSecurity",
		component: () => import("../views/EmailSecurity"),
		props: true,
		meta: {
			title: title + " - Email Security",
			canonical: "https://www.freetaxusa.com/email-security",
			metaTags: [
				{
					name: "description",
					content:
						"Protect yourself from email scams. Identify phishing attempts and safeguard your personal information. Learn how to spot red flags and report suspicious emails. File your taxes securely with FreeTaxUSA."
				},
				{
					name: "keywords",
					content: "tax support phishing fake email scam safety"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/espanol",
		name: "Espanol",
		component: () => import("../views/Espanol"),
		props: true,
		meta: {
			title: title + " Español — Declaración de impuestos federales gratis",
			canonical: "https://www.freetaxusa.com/espanol",
			metaTags: [
				{
					name: "description",
					content:
						"Declara impuestos federales 100% gratis. " +
						title +
						" te ayuda a presentar la declaración de impuestos electrónicamente al IRS con software seguro y fácil."
				},
				{
					name: "keywords",
					content:
						"gratis, impuestos, declarar, en línea, preparación, e-file, impuestos, efile, irs, preparar, federal, estatal, software, " +
						appYear
				}
			]
		}
	},
	{
		path: "/estimate",
		name: "Estimate",
		component: () => import("../views/Estimate"),
		props: true,
		meta: {
			title:
				title + " - Estimate your tax refund and file your federal taxes free.",
			canonical: "https://www.freetaxusa.com/estimate",
			metaTags: [
				{
					name: "description",
					content:
						"Sign in and estimate your " +
						appYear +
						" tax refund or taxes owed. When you're ready, update your entries, and file your federal tax return for free."
				},
				{
					name: "keywords",
					content: "income, tax, calculator, estimator, irs, federal, state"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/extension",
		name: "Extension",
		component: () => import("../views/Extension"),
		props: true,
		meta: {
			title:
				title +
				" Income Tax Extension, Federal Filing, E-File IRS Extension Online",
			canonical: "https://www.freetaxusa.com/extension",
			metaTags: [
				{
					name: "description",
					content:
						"Free tax return extension filing. Easily file your state and federal tax extension forms online. Extend the deadline with fast IRS e-file."
				},
				{
					name: "keywords",
					content: "extension, income, tax, filing, e-file, irs, form, federal"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms",
		name: "Forms",
		component: () => import("../views/Forms"),
		props: true,
		meta: {
			title: title + " Supported Federal Tax Forms",
			canonical: "https://www.freetaxusa.com/forms",
			metaTags: [
				{
					name: "description",
					content:
						"Find the " +
						appYear +
						" federal tax forms you need. Official IRS income tax forms are printable and can be downloaded for FREE. Get the current year income tax forms today!"
				},
				{
					name: "keywords",
					content:
						"income tax, supported, forms, download, free federal, individual, tax schedule, irs, online preparation"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms-2023",
		name: "Forms2023",
		component: () => import("../views/Forms2023.vue"),
		props: true,
		meta: {
			title: title + " Supported Federal Tax Forms 2023",
			canonical: "https://www.freetaxusa.com/forms-2023",
			metaTags: [
				{
					name: "description",
					content:
						"See supported federal and state tax forms for the 2023 tax year. View official IRS and state-specific forms numbers. Easy to start and complete. File your taxes with confidence."
				},
				{
					name: "keywords",
					content:
						"2023, income tax, supported, forms, download, free federal, individual, tax schedule, irs, online preparation"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms-federal-2017",
		name: "FormsFederal2017",
		component: () => import("../views/FormsFederal2017"),
		props: true,
		meta: {
			title: "2017 Federal Supported Tax Forms",
			canonical: "https://www.freetaxusa.com/forms-federal-2017",
			metaTags: [
				{
					name: "description",
					content:
						"File 2017 tax forms for free. See the supported tax forms for 2017 tax returns. Old taxes can be filed free with free federal tax preparation."
				},
				{
					name: "keywords",
					content: "tax, forms, federal, 2017, supported"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms-federal-2018",
		name: "FormsFederal2018",
		component: () => import("../views/FormsFederal2018"),
		props: true,
		meta: {
			title: "2018 Federal Supported Tax Forms",
			canonical: "https://www.freetaxusa.com/forms-federal-2018",
			metaTags: [
				{
					name: "description",
					content:
						"File 2018 tax forms for free. See the supported tax forms for 2018 tax returns. Old taxes can be filed free with free federal tax preparation."
				},
				{
					name: "keywords",
					content: "tax, forms, federal, 2018, supported"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms-federal-2019",
		name: "FormsFederal2019",
		component: () => import("../views/FormsFederal2019"),
		props: true,
		meta: {
			title: "2019 Federal Supported Tax Forms",
			canonical: "https://www.freetaxusa.com/forms-federal-2019",
			metaTags: [
				{
					name: "description",
					content:
						"File 2019 tax forms for free. See the supported tax forms for 2019 tax returns. Old taxes can be filed free with free federal tax preparation."
				},
				{
					name: "keywords",
					content: "tax, forms, federal, 2019, supported"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms-federal-2020",
		name: "FormsFederal2020",
		component: () => import("../views/FormsFederal2020"),
		props: true,
		meta: {
			title: "2020 Federal Supported Tax Forms",
			canonical: "https://www.freetaxusa.com/forms-federal-2020",
			metaTags: [
				{
					name: "description",
					content:
						"File 2020 tax forms for free. See the supported tax forms for 2020 tax returns. Old taxes can be filed free with free federal tax preparation."
				},
				{
					name: "keywords",
					content: "tax, forms, federal, 2020, supported"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms-federal-2021",
		name: "FormsFederal2021",
		component: () => import("../views/FormsFederal2021"),
		props: true,
		meta: {
			title: "2021 Federal Supported Tax Forms",
			canonical: "https://www.freetaxusa.com/forms-federal-2021",
			metaTags: [
				{
					name: "description",
					content:
						"File 2021 tax forms for free. See the supported tax forms for 2021 tax returns. Old taxes can be filed free with free federal tax preparation."
				},
				{
					name: "keywords",
					content: "tax, forms, federal, 2021, supported"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms-federal-2022",
		name: "FormsFederal2022",
		component: () => import("../views/FormsFederal2022"),
		props: true,
		meta: {
			title: "2022 Federal Supported Tax Forms",
			canonical: "https://www.freetaxusa.com/forms-federal-2022",
			metaTags: [
				{
					name: "description",
					content:
						"File 2022 tax forms for free. See the supported tax forms for 2022 tax returns. Old taxes can be filed free with free federal tax preparation."
				},
				{
					name: "keywords",
					content: "tax, forms, federal, 2022, supported"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms-state-2017",
		name: "FormsState2017",
		component: () => import("../views/FormsState2017"),
		props: true,
		meta: {
			title: title + " Supported State Tax Forms for 2017",
			canonical: "https://www.freetaxusa.com/forms-state-2017",
			metaTags: [
				{
					name: "description",
					content:
						"Supported state tax forms for 2017 prior year tax filing on FreeTaxUSA.com."
				},
				{
					name: "keywords",
					content: "2017,state,tax,forms,support"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms-state-2018",
		name: "FormsState2018",
		component: () => import("../views/FormsState2018"),
		props: true,
		meta: {
			title: title + " Supported State Tax Forms for 2018",
			canonical: "https://www.freetaxusa.com/forms-state-2018",
			metaTags: [
				{
					name: "description",
					content:
						"Supported state tax forms for 2018 prior year tax filing on FreeTaxUSA.com."
				},
				{
					name: "keywords",
					content: "2018,state,tax,forms,support"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms-state-2019",
		name: "FormsState2019",
		component: () => import("../views/FormsState2019"),
		props: true,
		meta: {
			title: title + " Supported State Tax Forms for 2019",
			canonical: "https://www.freetaxusa.com/forms-state-2019",
			metaTags: [
				{
					name: "description",
					content:
						"Supported state tax forms for 2019 prior year tax filing on FreeTaxUSA.com."
				},
				{
					name: "keywords",
					content: "2019,state,tax,forms,support"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms-state-2020",
		name: "FormsState2020",
		component: () => import("../views/FormsState2020"),
		props: true,
		meta: {
			title: title + " Supported State Tax Forms for 2020",
			canonical: "https://www.freetaxusa.com/forms-state-2020",
			metaTags: [
				{
					name: "description",
					content:
						"Supported state tax forms for 2020 prior year tax filing on FreeTaxUSA.com."
				},
				{
					name: "keywords",
					content: "2020,state,tax,forms,support"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms-state-2021",
		name: "FormsState2021",
		component: () => import("../views/FormsState2021"),
		props: true,
		meta: {
			title: title + " Supported State Tax Forms for 2021",
			canonical: "https://www.freetaxusa.com/forms-state-2021",
			metaTags: [
				{
					name: "description",
					content:
						"Supported state tax forms for 2021 prior year tax filing on FreeTaxUSA.com."
				},
				{
					name: "keywords",
					content: "2021,state,tax,forms,support"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms-state-2022",
		name: "FormsState2022",
		component: () => import("../views/FormsState2022"),
		props: true,
		meta: {
			title: title + " Supported State Tax Forms for 2022",
			canonical: "https://www.freetaxusa.com/forms-state-2022",
			metaTags: [
				{
					name: "description",
					content:
						"Supported state tax forms for 2022 prior year tax filing on FreeTaxUSA.com."
				},
				{
					name: "keywords",
					content: "2022,state,tax,forms,support"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/giveaway",
		name: "Giveaway",
		component: () => import("../views/Giveaway"),
		props: true,
		meta: {
			title: title + " Weekly & GrandPrize Giveaways - OFFICIAL RULES",
			canonical: "https://www.freetaxusa.com/giveaway",
			metaTags: [
				{
					name: "description",
					content: "Rules for entering the cash giveaway on FreeTaxUSA.com"
				},
				{
					name: "keywords",
					content: "giveaway,cash,rules,drawing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/group",
		name: "Group",
		component: () => import("../views/Group"),
		props: true,
		meta: {
			title:
				title +
				" FREE Tax Filing, Online Return Preparation, E-File Income Taxes",
			canonical: "https://www.freetaxusa.com/group",
			metaTags: [
				{
					name: "description",
					content:
						"100% Free Tax Filing. Efile your tax return directly to the IRS. Prepare federal and state income taxes online. " +
						appYear +
						" tax preparation software."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				}
			]
		}
	},
	{
		path: "/investor",
		name: "Investor",
		component: () => import("../views/Investor"),
		props: true,
		meta: {
			title: "Free IRS Tax Preparation with Investment Income",
			canonical: "https://www.freetaxusa.com/investor",
			metaTags: [
				{
					name: "description",
					content: "No extra fees to report your capital gains and losses. Free support for stocks, bonds, IRA distributions, interest and dividend income, and more. IRS e-file is included. "
				},
				{
					name: "keywords",
					content: "free, investment, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, stocks, bonds, capital gains"
				}
			]
		}
	},
	{
		path: "/low-income",
		name: "LowIncome",
		component: () => import("../views/LowIncome"),
		props: true,
		meta: {
			title: "$0 Federal Tax Filing for Low Income Filers | " + title,
			canonical: "https://www.freetaxusa.com/low-income",
			metaTags: [
				{
					name: "description",
					content:
						"Free federal tax filing for low-income individuals. Claim the Earned Income Tax Credit, Child Tax Credit, and more. Get your maximum refund guaranteed."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				}
			]
		}
	},
	{
		path: "/homeowner",
		name: "Homeowner",
		component: () => import("../views/Homeowner"),
		props: true,
		meta: {
			title:
				title +
				" Free Federal Tax Filing for Homeowners, Online Return Preparation",
			canonical: "https://www.freetaxusa.com/homeowner",
			metaTags: [
				{
					name: "description",
					content:
						"100% free federal tax filing. E-File your tax return directly to the IRS. Maximize your credits and deductions."
				},
				{
					name: "keywords",
					content:
						"free, homeowner, tax, filing, online, return, preparation, e-file, income, taxes, irs, prepare, federal, state, deductions, credits"
				}
			]
		}
	},
	{
		path: "/kicker-calculator",
		name: "KickerCalculator",
		component: () => import("../views/KickerCalculator"),
		props: true,
		meta: {
			title: title + " Oregon Kicker Calculator",
			canonical: "https://www.freetaxusa.com/kicker-calculator",
			metaTags: [
				{
					name: "description",
					content: "Calculate your Oregon kicker tax credit."
				},
				{
					name: "keywords",
					content:
						"oregon, state, taxes, kicker, calculator, " +
						appYear +
						" tax credits"
				}
			]
		}
	},
	{
		path: "/lending-tree",
		name: "Lendingtree",
		component: () => import("../views/Lendingtree"),
		props: true,
		meta: {
			title: title + " - Get your free credit score!",
			canonical: "https://www.freetaxusa.com/lending-tree",
			metaTags: [
				{
					name: "description",
					content: "Get your free credit score!"
				},
				{
					name: "keywords",
					content: "income, tax, credit score, irs, federal, state"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/married",
		name: "Married",
		component: () => import("../views/Married"),
		props: true,
		meta: {
			title: "Married Couple Tax Filing | File Jointly or Separately Today",
			canonical: "https://www.freetaxusa.com/married",
			metaTags: [
				{
					name: "description",
					content:
						"We guide you through how to file joint or separate taxes with support for all the credits, deductions, and forms you need. $0 Federal and only $14.99 State."
				},
				{
					name: "keywords",
					content:
						"free, married, tax, preparation, federal, state, income, taxes"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/military",
		name: "Military",
		component: () => import("../views/Military"),
		props: true,
		meta: {
			title:
				title +
				" Military Tax Preparation - Free Online Federal and $14.99 State Income Taxes",
			canonical: "https://www.freetaxusa.com/military",
			metaTags: [
				{
					name: "description",
					content:
						"Tax Filing for Active Duty and Retired Military Personnel. Online Income Tax Preparation. Federal and State Tax Returns. Start Now!"
				},
				{
					name: "keywords",
					content:
						"free, military, tax, preparation, federal, state, income, taxes"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/minor",
		name: "Minor",
		component: () => import("../views/Minor"),
		props: true,
		meta: {
			title:
				"Free Federal Tax Filing for Minors, Online Return Preparation, E-File Taxes",
			canonical: "https://www.freetaxusa.com/minor",
			metaTags: [
				{
					name: "description",
					content:
						"Easy tax filing for minors. FreeTaxUSA helps file your child's taxes, from wages to deductions. File federal returns online for free. Secure & simple for teens & younger children."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				}
			]
		}
	},
	{
		path: "/phishing",
		name: "Phishing",
		component: () => import("../views/Phishing"),
		props: true,
		meta: {
			title: title + " - Protect Yourself from Phishing or Fake Email Scams",
			canonical: "https://www.freetaxusa.com/phishing",
			metaTags: [
				{
					name: "description",
					content:
						"Information about protecting yourself from phishing and fake email scams"
				},
				{
					name: "keywords",
					content: "tax support phishing fake email scam safety"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/premium-credit",
		name: "PremiumCredit",
		component: () => import("../views/PremiumCredit"),
		props: true,
		meta: {
			title: title + " Affordable Care Act - Premium Tax Credit",
			canonical: "https://www.freetaxusa.com/premium-credit",
			metaTags: [
				{
					name: "description",
					content:
						"FreeTaxUSA  supports the Premium Tax Credit for those who bought insurance through the Marketplace as part of the the Affordable Care Act."
				},
				{
					name: "keywords",
					content: "affordable, care, act, premium, tax, credit"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/press",
		name: "Press",
		component: () => import("../views/Press"),
		props: true,
		meta: {
			title: title + " in the Media",
			canonical: "https://www.freetaxusa.com/press",
			metaTags: [
				{
					name: "description",
					content: "Mentions of FreeTaxUSA in the media."
				},
				{
					name: "keywords",
					content: "taxes,free,forbes,abcnews,media,mentions"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/pricing",
		name: "Pricing",
		component: () => import("../views/Pricing"),
		props: true,
		meta: {
			title: title + " Pricing: Affordable Filing for All Situations",
			canonical: "https://www.freetaxusa.com/pricing",
			metaTags: [
				{
					name: "description",
					content:
						"Simple or complex, federal taxes are 100% free and state returns are under $20. File today with no tiers and no forced upgrades."
				},
				{
					name: "keywords",
					content: "pricing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/prior-year",
		name: "PriorYear",
		component: () => import("../views/PriorYear"),
		props: true,
		meta: {
			title:
				"Prior Year Tax Return Software | File Previous Year Taxes with " +
				title,
			canonical: "https://www.freetaxusa.com/prior-year",
			metaTags: [
				{
					name: "description",
					content:
						"Prepare old tax returns online. Federal filing is always free. State filing is $17.99. File back taxes for " +
						(appYear - 1) +
						", " +
						(appYear - 2) +
						", " +
						(appYear - 3) +
						", and other years."
				},
				{
					name: "keywords",
					content: "prior,year,tax,return,software,previous,taxes"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/privacy",
		name: "Privacy",
		component: () => import("../views/Privacy"),
		props: true,
		meta: {
			title:
				title + " Privacy Policy Regarding Free Tax Preparation and E-File",
			canonical: "https://www.freetaxusa.com/privacy",
			metaTags: [
				{
					name: "description",
					content:
						"FreeTaxUSA treats your financial and personally identifiable information as confidential. All information gathered is encrypted using secure socket layer technology (SSL)."
				},
				{
					name: "keywords",
					content: "privacy,statement,confidential,information"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/pro",
		name: "Pro",
		component: () => import("../views/Pro"),
		props: true,
		meta: {
			title:
				title +
				" Pro - File your taxes online and get the help of a tax expert when needed.",
			canonical: "https://www.freetaxusa.com/pro",
			metaTags: [
				{
					name: "description",
					content:
						"Start your tax return and enter your information. When you have a specific question, you'll be able to schedule a virtual meeting with a CPA or EA."
				},
				{
					name: "keywords",
					content: "tax,pro,expert,help,cpa,ea,assistance,questions"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/referral-thanks",
		name: "ReferralThanks",
		component: () => import("../views/ReferralThanks"),
		props: true,
		meta: {
			title: "Friend Referral Confirmation",
			canonical: "https://www.freetaxusa.com/referral-thanks",
			metaTags: [
				{
					name: "description",
					content: "Tell A Friend Confirmation"
				},
				{
					name: "keywords",
					content:
						"federal return returns e-file e-filing irs refund efile electronic filing do free income tax preparation online prepare cash drawings giveaway referral"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/refund-status",
		name: "RefundStatus",
		component: () => import("../views/RefundStatus"),
		props: true,
		meta: {
			title: "Where's My Refund",
			canonical: "https://www.freetaxusa.com/refund-status",
			metaTags: [
				{
					name: "description",
					content:
						"Follow these steps to check the status of your refund with the IRS."
				},
				{
					name: "keywords",
					content: "irs,where,refund"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/rental-income",
		name: "RentalIncome",
		component: () => import("../views/RentalIncome"),
		props: true,
		meta: {
			title:
				"Free Federal Tax Filing with Rental Income, Online Return Preparation",
			canonical: "https://www.freetaxusa.com/rental-income",
			metaTags: [
				{
					name: "description",
					content:
						"100% free federal tax filing. E-File your Schedule E directly to the IRS. " + appYear + " tax preparation software."
				},
				{
					name: "keywords",
					content:
						"free, rental, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, deductions"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/retired",
		name: "Retired",
		component: () => import("../views/Retired"),
		props: true,
		meta: {
			title:
				"Free Federal Tax Filing for Retirees, Online Return Preparation, E-File Taxes",
			canonical: "https://www.freetaxusa.com/retired",
			metaTags: [
				{
					name: "description",
					content:
						"Effortless tax filing for retirees. FreeTaxUSA guides you through your retirement income, deductions, and credits. File your federal & state tax return online for free. Save money & keep more of what's yours."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				}
			]
		}
	},
	{
		path: "/return-copy",
		name: "ReturnCopy",
		component: () => import("../views/ReturnCopy"),
		props: true,
		meta: {
			title:
				title +
				" Get a Copy of Your Past Tax Return FREE - IRS Request Instructions",
			canonical: "https://www.freetaxusa.com/return-copy",
			metaTags: [
				{
					name: "description",
					content:
						"Printing a copy of your old tax return on FreeTaxUSA is FREE.  IRS instructions help you get past year returns easily."
				},
				{
					name: "keywords",
					content:
						"copy, printing, past, tax, return, free, federal, irs, state, income, tax"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/reviews",
		name: "Reviews",
		component: () => import("../views/Reviews"),
		props: true,
		meta: {
			title: title + " Customer Reviews",
			canonical: "https://www.freetaxusa.com/reviews",
			metaTags: [
				{
					name: "description",
					content: "Read thousands of 5 star reviews from satisfied customers."
				},
				{
					name: "keywords",
					content: "customer,reviews,consumer,ratings"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/safe",
		name: "Safe",
		component: () => import("../views/Safe"),
		props: true,
		meta: {
			title:
				"Your information is secure with data encryption, account alerts, and two-factor authentication.",
			canonical: "https://www.freetaxusa.com/safe",
			metaTags: [
				{
					name: "description",
					content:
						"Your information is safe. See how we protect your account with encryption, 2-factor authentication, security certificates, and account alerts."
				},
				{
					name: "keywords",
					content:
						"secure,safe,tax,information,encryption,privacy, security, protection, authorized"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/schedule-a",
		name: "ScheduleA",
		component: () => import("../views/ScheduleA"),
		props: true,
		meta: {
			title:
				"Free Schedule A Online Filing - How to E-File a Schedule A Form on " +
				title,
			canonical: "https://www.freetaxusa.com/schedule-c",
			metaTags: [
				{
					name: "description",
					content:
						"File your Schedule A with the IRS for free. Itemize your deductions fast with mortgage interest, healthcare expenses, charitable contributions, and more."
				},
				{
					name: "keywords",
					content: "schedule-a, deductions, file, e-file, online, free, filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/schedule-b",
		name: "ScheduleB",
		component: () => import("../views/ScheduleB"),
		props: true,
		meta: {
			title:
				"Free Schedule B Online Filing - How to E-File a Schedule B Form on " +
				title,
			canonical: "https://www.freetaxusa.com/schedule-c",
			metaTags: [
				{
					name: "description",
					content:
						"File your Schedule B for free. Report interest from a bond or dividend income earned. E-File schedules for form 1040 and form 1040-SR using FreeTaxUSA!"
				},
				{
					name: "keywords",
					content:
						"schedule-b, interest, dividend, income, file, e-file, online, free, filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/schedule-c",
		name: "ScheduleC",
		component: () => import("../views/ScheduleC"),
		props: true,
		meta: {
			title:
				"Free Schedule C Online Filing - How to E-File a Schedule C Form on " +
				title,
			canonical: "https://www.freetaxusa.com/schedule-c",
			metaTags: [
				{
					name: "description",
					content:
						"File your Schedule C with the IRS for free. Report self-employment income and expenses. E-File schedules for form 1040 and form 1040-SR using FreeTaxUSA!"
				},
				{
					name: "keywords",
					content:
						"schedule-c, self-employed, freelancer, sole, proprietor, contractor, consultant, income, file, e-file, online, free, filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/schedule-d",
		name: "ScheduleD",
		component: () => import("../views/ScheduleD"),
		props: true,
		meta: {
			title:
				"Free Schedule D Online Filing - How to E-File a Schedule D Form on " +
				title,
			canonical: "https://www.freetaxusa.com/schedule-c",
			metaTags: [
				{
					name: "description",
					content:
						"Report gains or losses from the sale or trade of crypto, stock, and other capital assets. E-File schedules for form 1040 and form 1040-SR using FreeTaxUSA!"
				},
				{
					name: "keywords",
					content:
						"schedule-d, sale, trade, crypto, stock, capital, assets, file, e-file, online, free, filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/schedule-e",
		name: "ScheduleE",
		component: () => import("../views/ScheduleE"),
		props: true,
		meta: {
			title:
				"Free Schedule E Online Filing - How to E-File a Schedule E Form on " +
				title,
			canonical: "https://www.freetaxusa.com/schedule-c",
			metaTags: [
				{
					name: "description",
					content:
						"File your Schedule E with the IRS for free. Report income or loss from rental real estate, royalties, partnerships, S corporations, estates, trusts, and more."
				},
				{
					name: "keywords",
					content:
						"schedule-e, rental, real, estate, supplemental, income, file, e-file, online, free, filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/schedule-f",
		name: "ScheduleF",
		component: () => import("../views/ScheduleF"),
		props: true,
		meta: {
			title:
				"Free Schedule F Online Filing - How to E-File a Schedule F Form on " +
				title,
			canonical: "https://www.freetaxusa.com/schedule-c",
			metaTags: [
				{
					name: "description",
					content:
						"File your Schedule F with the IRS for free. Report all income and expenses for farming. E-File Schedules for form 1040 and form 1040-SR using FreeTaxUSA!"
				},
				{
					name: "keywords",
					content:
						"schedule-f, farm, income, file, e-file, online, free, filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/schedule-k-1",
		name: "ScheduleK1",
		component: () => import("../views/ScheduleK1"),
		props: true,
		meta: {
			title:
				"Free Schedule K-1 Online Filing - How to E-File a Schedule kK1 Form on " +
				title,
			canonical: "https://www.freetaxusa.com/schedule-c",
			metaTags: [
				{
					name: "description",
					content:
						"File your Schedule K-1 with the IRS for free. Report business income, deductions, and credits for business partnerships, LLCs, S corps, trusts, and estates."
				},
				{
					name: "keywords",
					content:
						"schedule, K-1, income, deductions, credits, business, partnerships, LLC, file, e-file, online, free, filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/schedule-se",
		name: "ScheduleSE",
		component: () => import("../views/ScheduleSE"),
		props: true,
		meta: {
			title:
				"Free Schedule SE Online Filing - How to E-File a Schedule SE Form on " +
				title,
			canonical: "https://www.freetaxusa.com/schedule-c",
			metaTags: [
				{
					name: "description",
					content:
						"File your Schedule SE for free. Calculate taxes due on net earnings from self-employment. E-File Schedules for Form 1040 and Form 1040-SR using FreeTaxUSA!"
				},
				{
					name: "keywords",
					content:
						"schedule-se, self-employed, freelancer, sole, proprietor, contractor, consultant, income, file, e-file, online, free, filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/schedule-1",
		name: "Schedule1",
		component: () => import("../views/Schedule1"),
		props: true,
		meta: {
			title:
				"Free Schedule 1 Online Filing - How to E-File a Schedule 1 Form on " +
				title,
			canonical: "https://www.freetaxusa.com/schedule-c",
			metaTags: [
				{
					name: "description",
					content:
						"File your Schedule 1 with the IRS for free. Report state/local tax refunds, alimony, unemployment, gambling income, stock options, and certain deductions."
				},
				{
					name: "keywords",
					content:
						"schedule-1, additional, income, tax, refunds, alimony, unemployment, gambling, stock, deductions, file, e-file, online, free, filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/schedule-2",
		name: "Schedule2",
		component: () => import("../views/Schedule2"),
		props: true,
		meta: {
			title:
				"Free Schedule 2 Online Filing - How to E-File a Schedule 2 Form on " +
				title,
			canonical: "https://www.freetaxusa.com/schedule-c",
			metaTags: [
				{
					name: "description",
					content:
						"File your Schedule 2 with the IRS for free. File additional taxes such as alternative minimum tax, self-employment tax, household employment taxes, and more."
				},
				{
					name: "keywords",
					content:
						"schedule-2, additional, taxes, self, employment, household, alternative, minimum, file, e-file, online, free, filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/schedule-3",
		name: "Schedule3",
		component: () => import("../views/Schedule3"),
		props: true,
		meta: {
			title:
				"Free Schedule 3 Online Filing - How to E-File a Schedule 3 Form on " +
				title,
			canonical: "https://www.freetaxusa.com/schedule-c",
			metaTags: [
				{
					name: "description",
					content:
						"File your Schedule 3 for free. File nonrefundable credits and payments and claim credits for dependent care, education, energy, foreign taxes, and more."
				},
				{
					name: "keywords",
					content:
						"schedule-3, nonrefundable, credits, dependent, care, education, energy, foreign, taxes, file, e-file, online, free, filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/secure",
		name: "Secure",
		component: () => import("../views/Secure"),
		props: true,
		meta: {
			title:
				"Review our security certificates and verify compliance with DigiCert, SecurityMetrics, and Truste.",
			canonical: "https://www.freetaxusa.com/secure",
			metaTags: [
				{
					name: "description",
					content:
						"We keep your data safe by ensuring our privacy and security practices are aligned with industry standards."
				},
				{
					name: "keywords",
					content:
						"ecurity, certificate, safe, privacy, policy, accredited business, tax, filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/self-employed",
		name: "SelfEmployed",
		component: () => import("../views/SelfEmployed"),
		props: true,
		meta: {
			title: "Free IRS Tax Filing Includes Self-Employed Tax Forms (1099) ",
			canonical: "https://www.freetaxusa.com/self-employed",
			metaTags: [
				{
					name: "description",
					content: "Free support for independent contractors, freelancers, sole proprietors and more. Hundreds of deductions, write-offs, and credits built into the online software. IRS e-file is included. "
				},
				{
					name: "keywords",
					content: "free, self employed, tax, filing, contractors, return, preparation, e-file, income, taxes, efile, irs, prepare, 1099, deductions, credits"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/seniors",
		name: "Seniors",
		component: () => import("../views/Seniors"),
		props: true,
		meta: {
			title:
				"Free Federal Senior Tax Filing, Online Return Preparation, E-File Taxes",
			canonical: "https://www.freetaxusa.com/seniors",
			metaTags: [
				{
					name: "description",
					content:
						"Federal taxes done free for seniors. Includes 1040 SR, and Credit for the Elderly or Disabled. Our software assists you in preparing your tax return, getting you the biggest refund possible."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				}
			]
		}
	},
	{
		path: "/share",
		name: "Share",
		component: () => import("../views/Share"),
		props: true,
		meta: {
			title: "Tell Your Friends About " + title,
			canonical: "https://www.freetaxusa.com/share",
			metaTags: [
				{
					name: "description",
					content:
						"Tell your friends about the offer on FreeTaxUSA using this email tool. Everyone can prepare, print, and e-file their federal taxes for free."
				},
				{
					name: "keywords",
					content: "share,follow,email,friend,post,facebook,x,social"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/sitemap",
		name: "Sitemap",
		component: () => import("../views/Sitemap"),
		props: true,
		meta: {
			title: title + " Sitemap & Helpful Links",
			canonical: "https://www.freetaxusa.com/sitemap",
			metaTags: [
				{
					name: "description",
					content:
						"FreeTaxUSA sitemap can guide you on e-filing and preparation of your federal tax return online for free. Most people can complete their income tax return within 30 minutes."
				},
				{
					name: "keywords",
					content:
						"free efile preparation electronic filing income tax preparation online prepare federal return returns e-file e-filing irs refund"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/small-business",
		name: "SmallBusiness",
		component: () => import("../views/SmallBusiness"),
		props: true,
		meta: {
			title: "Free IRS Tax Preparation for Small Business Owners",
			canonical: "https://www.freetaxusa.com/small-business",
			metaTags: [
				{
					name: "description",
					content: "Free support for sole proprietors and self-employed. Hundreds of deductions, write-offs, and credits built into the online software. IRS e-file is included. "
				},
				{
					name: "keywords",
					content: " free, business owner, tax, filing, online, return, preparation, e-file, income, taxes, irs, prepare, federal, state, deductions, credits"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/software",
		name: "Software",
		component: () => import("../views/Software"),
		props: true,
		meta: {
			title:
				"Free Federal Tax Software | Alternative to TurboTax, H&R Block, and TaxAct",
			canonical: "https://www.freetaxusa.com/software",
			metaTags: [
				{
					name: "description",
					content:
						"Switching is easy! Compare our free federal filing to other tax softwares'. No hidden fees, no forced upgrades. Simple or complex, we’ve got you covered."
				},
				{
					name: "keywords",
					content:
						"free,tax,software,pricing,service,details,support,compare,reviews"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/state-privacy",
		name: "StatePrivacy",
		component: () => import("../views/StatePrivacy"),
		props: true,
		meta: {
			title: "State Privacy Policies on " + title,
			canonical: "https://www.freetaxusa.com/state-privacy",
			metaTags: [
				{
					name: "description",
					content:
						"If you would like to make a state specific privacy request please follow these instructions."
				},
				{
					name: "keywords",
					content: "California,Consumer,Privacy,Act"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/stock",
		name: "Stock",
		component: () => import("../views/Stock"),
		props: true,
		meta: {
			title:
				title +
				" Best Tax Software for Investors and Stock Traders - Easily Report Sale, Capital Gains, and Losses",
			canonical: "https://www.freetaxusa.com/stock",
			metaTags: [
				{
					name: "description",
					content:
						"Easy import for investors. Enter your stock sales information and then prepare and e-file your federal taxes on FreeTaxUSA. Free federal premium taxes."
				},
				{
					name: "keywords",
					content:
						"free, stock, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				}
			]
		}
	},
	{
		path: "/student",
		name: "Student",
		component: () => import("../views/Student"),
		props: true,
		meta: {
			title:
				"Free IRS Tax Filing for Students, Includes Tuition Tax Forms (1098-T)",
			canonical: "https://www.freetaxusa.com/student",
			metaTags: [
				{
					name: "description",
					content:
						"Free support for education credits, tuition and student loan interest statements. File Forms 1098-T, 1098-E and 8863 to the IRS for free."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				}
			]
		}
	},
	{
		path: "/tax-preparer",
		name: "TaxPreparer",
		component: () => import("../views/TaxPreparer.vue"),
		props: true,
		meta: {
			title:
				"File as a Paid Preparer Today | " + title + " Official Site",
			canonical: "https://www.freetaxusa.com/tax-preparer",
			metaTags: [
				{
					name: "description",
					content:
						"File client tax returns fast. Sign returns, import W-2s, IRS e-file, and more. Federal is always free & state tax returns are only $14.99 each."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, paid preparer, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				}
			]
		}
	},
	{
		path: "/unsubscribe",
		name: "Unsubscribe",
		component: () => import("../views/Unsubscribe"),
		meta: {
			title:
				"Unsubscribe from Promotional and Tax Tip Information Provided by " +
				title,
			canonical: "https://www.freetaxusa.com/unsubscribe",
			metaTags: [
				{
					name: "description",
					content:
						"Enter your email in order to unsubscribe from FreeTaxUSA promotional emails."
				},
				{
					name: "keywords",
					content: "email,subscriptions,update,unsubscribe"
				}
			]
		}
	},
	{
		path: "/subscribe",
		name: "Subscribe",
		component: () => import("../views/Subscribe"),
		meta: {
			title:
				"Subscribe to Promotional and Tax Tip Information Provided by " + title,
			canonical: "https://www.freetaxusa.com/subscribe",
			metaTags: [
				{
					name: "description",
					content:
						"Get the latest tax tips and promotional offers from FreeTaxUSA. With FreeTaxUSA.com, form 1040 is simple to use. Get your refund the fastest way possible."
				},
				{
					name: "keywords",
					content: "email,subscriptions,update,subscribe"
				}
			]
		}
	},
	{
		path: "/support",
		name: "Support",
		component: () => import("../views/Support"),
		props: true,
		meta: {
			title:
				title + " Customer Support for Federal Income Tax Return Questions",
			canonical: "https://www.freetaxusa.com/support",
			metaTags: [
				{
					name: "description",
					content:
						"Free customer support for FreeTaxUSA users. Ask tax questions and get quick answers online. We support income tax, rebate, refund, deduction questions and more!"
				},
				{
					name: "keywords",
					content:
						"customer,support,contact,email,account,questions,answers,form"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/survey",
		name: "Survey",
		component: () => import("../views/Survey"),
		props: true,
		meta: {
			title: title + " Survey - Please Tell Us How We Could Improve",
			canonical: "https://www.freetaxusa.com/survey",
			metaTags: [
				{
					name: "description",
					content:
						"Please tell us what you think about our service. How could we improve our tax software?"
				},
				{
					name: "keywords",
					content: "survey,improve,questions"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/switch",
		name: "Switch",
		component: () => import("../views/Switch"),
		props: true,
		meta: {
			title: "Always Free Federal Tax Filing on " + title + " Make the Switch",
			canonical: "https://www.freetaxusa.com/switch",
			metaTags: [
				{
					name: "description",
					content:
						"File premium federal taxes for $0. Federal tax returns are always free, for everyone, for all situations."
				},
				{
					name: "keywords",
					content:
						"self, employed, home, based, sole, proprietor, contractor, consultant, freelancer, tax, preparation, income, software, write-offs, breaks, e-file"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/tax-tips",
		name: "TaxTips",
		component: () => import("../views/TaxTips"),
		props: true,
		meta: {
			title: title + " Help & Tax Tips",
			canonical: "https://www.freetaxusa.com/tax-tips",
			metaTags: [
				{
					name: "description",
					content:
						"Ask tax questions and get quick answers online. We support income tax, rebate, refund, deduction questions & more!"
				},
				{
					name: "keywords",
					content: "tax,tips,help,answers"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/terms",
		name: "Terms",
		component: () => import("../views/Terms"),
		props: true,
		meta: {
			title: title + " website terms of use",
			canonical: "https://www.freetaxusa.com/terms",
			metaTags: [
				{
					name: "description",
					content:
						"These Terms and Conditions of Use apply to the FreeTaxUSA website. By using the site, you agree to these terms of use."
				},
				{
					name: "keywords",
					content: "terms,conditions,use,service"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/unemployment",
		name: "Unemployment",
		component: () => import("../views/Unemployment"),
		props: true,
		meta: {
			title: "Free Unemployment Tax Filing - E-File Federal 100% Free",
			canonical: "https://www.freetaxusa.com/unemployment",
			metaTags: [
				{
					name: "description",
					content:
						"If you received unemployment income you can still file your federal return free. IRS e-file is included."
				},
				{
					name: "keywords",
					content: "recovery,rebate,credit,tax,filing,return,software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/vulnerability-disclosure-policy",
		name: "VulnerabilityDisclosurePolicy",
		component: () => import("../views/VulnerabilityDisclosurePolicy"),
		props: true,
		meta: {
			title: title + " Vulnerability Disclosure Policy",
			canonical: "https://www.freetaxusa.com/vulnerability-disclosure-policy",
			metaTags: [
				{
					name: "description",
					content: "FreeTaxUSA Vulnerability Disclosure Policy."
				},
				{
					name: "keywords",
					content:
						"vulnerability,disclosure,statement,confidential,information"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	// {
	//   path: '/version',
	//   name: 'Version',
	//   component: () => import('../views/Version'),
	//   props: true,
	//   meta: {
	//     title: '',
	//     canonical: 'https://www.freetaxusa.com/version',
	//     metaTags: [
	//       {
	//         name: 'description',
	//         content: ''
	//       },
	//       {
	//         name: 'keywords',
	//         content: ''
	//       },
	//       {
	//         name: 'robots',
	//         content: 'index, follow'
	//       }
	//     ]
	//   },
	// },

	//    freefile *****************************************************************************************************
	{
		path: "/freefile2021",
		name: "Freefile2021",
		component: () => import("../views/Freefile2021"),
		props: true,
		meta: {
			title: "IRS Free File program delivered by " + title,
			canonical: "https://www.freetaxusa.com/freefile2021",
			metaTags: [
				{
					name: "description",
					content:
						"Free federal tax filing for those that qualify. Federal filing is $0 - $14.99 if you don't qualify."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				},
				{
					name: "robots",
					content: "noindex, nofollow"
				}
			]
		}
	},
	{
		path: "/freefile2022",
		name: "Freefile2022",
		component: () => import("../views/Freefile2022"),
		props: true,
		meta: {
			title: "IRS Free File program delivered by " + title,
			canonical: "https://www.freetaxusa.com/freefile2022",
			metaTags: [
				{
					name: "description",
					content:
						"Free federal tax filing for those that qualify. Federal filing is $0 - $14.99 if you don't qualify."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				},
				{
					name: "robots",
					content: "noindex, nofollow"
				}
			]
		}
	},
	{
		path: "/freefile2023",
		name: "Freefile2023",
		component: () => import("../views/Freefile2023"),
		props: true,
		meta: {
			title: "IRS Free File program delivered by " + title,
			canonical: "https://www.freetaxusa.com/freefile2023",
			metaTags: [
				{
					name: "description",
					content:
						"Free federal tax filing for those that qualify. Federal filing is $0 - $14.99 if you don't qualify."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				},
				{
					name: "robots",
					content: "no index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024",
		name: "freefile2024",
		component: () => import("../views/freefile2024/Home"),
		props: true,
		meta: {
			title: "IRS Free File program delivered by " + title,
			canonical: "https://www.freetaxusa.com/freefile2024",
			metaTags: [
				{
					name: "description",
					content:
						"Free federal tax filing for those that qualify. Federal filing is $0 - $14.99 if you don't qualify."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/about",
		name: "freefile2024/About",
		component: () => import("../views/freefile2024/About"),
		props: true,
		meta: {
			title: "Information About " + title + " - Best Value for Your Money",
			canonical: "https://www.freetaxusa.com/freefile2024/about",
			metaTags: [
				{
					name: "description",
					content:
						"FreeTaxUSA: America's trusted online tax preparation service. Founded in 2001, we offer free federal & low-cost state tax filing. Secure, easy-to-use, and backed by expert support. Save money & file your taxes with confidence."
				},
				{
					name: "keywords",
					content:
						"tax support income deduction refund supporting info about company federal efile returns"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/accessibility",
		name: "freefile2024/Accessibility",
		component: () => import("../views/freefile2024/Accessibility"),
		props: true,
		meta: {
			title: "Accessibility - " + title,
			canonical: "https://www.freetaxusa.com/freefile2024/accessibility",
			metaTags: [
				{
					name: "description",
					content:
						"Accessible Tax Filing at FreeTaxUSA. We support standards for users with disabilities. Get help filing your taxes with features designed for everyone. Contact us for assistance."
				},
				{
					name: "keywords",
					content:
						"tax support income deduction refund supporting info about company federal efile returns"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/account-recovery",
		name: "freefile2024/AccountRecovery",
		component: () => import("../views/freefile2024/AccountRecovery"),
		props: true,
		meta: {
			title: "Sign In and Recover Your FreeTaxUSA® Account",
			canonical: "https://www.freetaxusa.com/freefile2024/account-recovery",
			metaTags: [
				{
					name: "description",
					content:
						"Need help accessing your account? We'll help you recover your account with links to restore your password and username."
				},
				{
					name: "keywords",
					content: "forgot,password,username,locked,out,account,restore,access"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/answers",
		name: "freefile2024/Answers",
		component: () => import("../views/freefile2024/Answers"),
		props: true,
		meta: {
			title: "title",
			canonical: "https://www.freetaxusa.com/freefile2024/answers?faq=",
			metaTags: [
				{
					name: "description",
					content: "answers to frequently asked questions"
				},
				{
					name: "keywords",
					content:
						"answers, FAQs, frequently asked questions, questions and answers, questions"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/answers-search",
		name: "freefile2024/AnswersSearch",
		component: () => import("../views/freefile2024/AnswersSearch"),
		props: true,
		meta: {
			title: title + " - Questions and Answers",
			canonical: "https://www.freetaxusa.com/freefile2024/answers-search",
			metaTags: [
				{
					name: "description",
					content:
						"Find quick answers to your tax questions with FreeTaxUSA's comprehensive search. Explore our vast library of tax articles, FAQs, and expert advice to easily navigate the tax filing process."
				},
				{
					name: "keywords",
					content:
						"tax, support, income, deduction, refund, supporting, federal, technical, returns"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/contact",
		name: "freefile2024/Contact",
		component: () => import("../views/freefile2024/Contact"),
		props: true,
		meta: {
			title:
				"Contact " +
				title +
				" with Questions and Comments - Website, Company, & Affiliate Questions",
			canonical: "https://www.freetaxusa.com/freefile2024/contact",
			metaTags: [
				{
					name: "description",
					content:
						"We appreciate your feedback. We will respond as soon as possible. Please be advised that this response may be delayed by weekends and holidays."
				},
				{
					name: "keywords",
					content: "contact, us, freetaxusa"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/email-security",
		name: "freefile2024/EmailSecurity",
		component: () => import("../views/freefile2024/EmailSecurity"),
		props: true,
		meta: {
			title: title + " - Email Security",
			canonical: "https://www.freetaxusa.com/freefile2024/email-security",
			metaTags: [
				{
					name: "description",
					content:
						"Protect yourself from email scams. Identify phishing attempts and safeguard your personal information. Learn how to spot red flags and report suspicious emails. File your taxes securely with FreeTaxUSA."
				},
				{
					name: "keywords",
					content: "tax support phishing fake email scam safety"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/email-removal",
		name: "/freefile2024/EmailRemoval",
		component: () => import("../views/freefile2024/EmailRemoval"),
		props: true,
		meta: {
			title: "If you didn't create a " + title + "account",
			canonical: "https://www.freetaxusa.com/email-removal",
			metaTags: [
				{
					name: "description",
					content:
						"Report a wrong email address to unsubscribe from future mailings"
				},
				{
					name: "keywords",
					content: "unsubscribe,wrong,email"
				}
			]
		}
	},
	{
		path: "/freefile2024/extension",
		name: "freefile2024/Extension",
		component: () => import("../views/freefile2024/Extension"),
		props: true,
		meta: {
			title:
				title +
				"  Income Tax Extension, Federal Filing, E-File IRS Extension Online",
			canonical: "https://www.freetaxusa.com/freefile2024/extension",
			metaTags: [
				{
					name: "description",
					content:
						"FREE tax return extension filing. Easily file your state and federal tax extension forms online. Extend the deadline with fast IRS efile."
				},
				{
					name: "keywords",
					content: "extension, income, tax, filing, e-file, irs, form, federal"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/forms",
		name: "freefile2024/Forms",
		component: () => import("../views/freefile2024/Forms"),
		props: true,
		meta: {
			title:
				"Supported Tax Forms - " +
				appYear +
				" Deductions, Credits, & Income Tax Forms",
			canonical: "https://www.freetaxusa.com/freefile2024/forms",
			metaTags: [
				{
					name: "description",
					content:
						"Supported income forms include W-2, 1099, Schedule C, Schedule E and more. Deduction and credit forms include 1098, 2441, EIC and many more."
				},
				{
					name: "keywords",
					content:
						"free tax prep form 1040 income tax preparation online prepare federal return returns e-file e-filing irs refund efile electronic filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/guarantee",
		name: "freefile2024/Guarantee",
		component: () => import("../views/freefile2024/Guarantee"),
		props: true,
		meta: {
			title: title + "  Accuracy Guarantee",
			canonical: "https://www.freetaxusa.com/freefile2024/guarantee",
			metaTags: [
				{
					name: "description",
					content:
						"Information about our company. FreeTaxUSA data for users. Find out why we support all the income tax, rebate, refund, deduction, and credit you need."
				},
				{
					name: "keywords",
					content:
						"tax support income deduction refund supporting info about company federal efile returns"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/privacy",
		name: "freefile2024/Privacy",
		component: () => import("../views/freefile2024/Privacy"),
		props: true,
		meta: {
			title:
				title + "  Privacy Policy Regarding Free Tax Preparation and E-File",
			canonical: "https://www.freetaxusa.com/freefile2024/privacy",
			metaTags: [
				{
					name: "description",
					content:
						"FreeTaxUSA treats your financial and personally identifiable information as confidential. All information gathered is encrypted using secure socket layer technology (SSL)."
				},
				{
					name: "keywords",
					content: "privacy,statement,confidential,information"
				},
				{
					name: "robots",
					content: "noindex, nofollow"
				}
			]
		}
	},
	{
		path: "/freefile2024/refund-status",
		name: "freefile2024/RefundStatus",
		component: () => import("../views/freefile2024/RefundStatus"),
		props: true,
		meta: {
			title: "Where's My Refund",
			canonical: "https://www.freetaxusa.com/freefile2024/refund-status",
			metaTags: [
				{
					name: "description",
					content:
						"Follow these steps to check the status of your refund with the IRS."
				},
				{
					name: "keywords",
					content: "irs,where,refund"
				}
			]
		}
	},
	{
		path: "/freefile2024/return-copy",
		name: "freefile2024/ReturnCopy",
		component: () => import("../views/freefile2024/ReturnCopy"),
		props: true,
		meta: {
			title:
				title +
				"  Get a Copy of Your Past Tax Return FREE - IRS Request Instructions",
			canonical: "https://www.freetaxusa.com/freefile2024/return-copy",
			metaTags: [
				{
					name: "description",
					content:
						"Printing a copy of your old tax return on FreeTaxUSA is FREE.  IRS instructions help you get past year returns easily."
				},
				{
					name: "keywords",
					content:
						"copy, printing, past, tax, return, free, federal, irs, state, income, tax"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/secure",
		name: "freefile2024/Secure",
		component: () => import("../views/freefile2024/Secure"),
		props: true,
		meta: {
			title: "Your tax information is private and safe on " + title,
			canonical: "https://www.freetaxusa.com/freefile2024/secure",
			metaTags: [
				{
					name: "description",
					content:
						"Review our security, encryption, and privacy practices. We have securely filed millions of tax returns."
				},
				{
					name: "keywords",
					content: "secure,safe,tax,information,encryption,privacy"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/sitemap",
		name: "freefile2024/Sitemap",
		component: () => import("../views/freefile2024/Sitemap"),
		props: true,
		meta: {
			title: title + "  Sitemap & Helpful Links",
			canonical: "https://www.freetaxusa.com/freefile2024/sitemap",
			metaTags: [
				{
					name: "description",
					content:
						"FreeTaxUSA sitemap can guide you on e-filing and preparation of your federal tax return online for free. Most people can complete their income tax return within 30 minutes."
				},
				{
					name: "keywords",
					content:
						"free efile preparation electronic filing income tax preparation online prepare federal return returns e-file e-filing irs refund"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/state-privacy",
		name: "freefile2024/StatePrivacy",
		component: () => import("../views/freefile2024/StatePrivacy"),
		props: true,
		meta: {
			title: "State Privacy Policies on " + title,
			canonical: "https://www.freetaxusa.com/freefile2024/state-privacy",
			metaTags: [
				{
					name: "description",
					content:
						"If you would like to make a state specific privacy request please follow these instructions."
				},
				{
					name: "keywords",
					content: "California,Consumer,Privacy,Act"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/unsubscribe",
		name: "freefile2024/Unsubscribe",
		component: () => import("../views/freefile2024/Unsubscribe"),
		props: true,
		meta: {
			title:
				"Unsubscribe from Promotional and Tax Tip Information Provided by " +
				title,
			canonical: "https://www.freetaxusa.com/freefile2024/unsubscribe",
			metaTags: [
				{
					name: "description",
					content:
						"Get answers to tax preparation and e-file questions for free. With freetaxusa.com, form 1040 is simple to use. You can receive your refund the fastest way possible."
				},
				{
					name: "keywords",
					content:
						"form 1040 income tax preparation online federal and state return returns free tax prep e-file e-filing irs refund efile electronic filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/subscribe",
		name: "freefile2024/Subscribe",
		component: () => import("../views/freefile2024/Subscribe"),
		props: true,
		meta: {
			title:
				"Subscribe to Promotional and Tax Tip Information Provided by " + title,
			canonical: "https://www.freetaxusa.com/freefile2024/subscribe",
			metaTags: [
				{
					name: "description",
					content:
						"Get answers to tax preparation and e-file questions for free. With freetaxusa.com, form 1040 is simple to use. You can receive your refund the fastest way possible."
				},
				{
					name: "keywords",
					content:
						"form 1040 income tax preparation online federal and state return returns free tax prep e-file e-filing irs refund efile electronic filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/support",
		name: "freefile2024/Support",
		component: () => import("../views/freefile2024/Support"),
		props: true,
		meta: {
			title:
				title + "  Customer Support for Federal Income Tax Return Questions",
			canonical: "https://www.freetaxusa.com/freefile2024/support",
			metaTags: [
				{
					name: "description",
					content:
						"Free customer support for FreeTaxUSA users. Ask tax questions and get quick answers online. We support income tax, rebate, refund, deduction questions and more!"
				},
				{
					name: "keywords",
					content:
						"customer,support,contact,email,account,questions,answers,form"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/survey",
		name: "freefile2024/Survey",
		component: () => import("../views/freefile2024/Survey"),
		props: true,
		meta: {
			title: "IRS Free File program survey",
			canonical: "https://www.freetaxusa.com/freefile2024/survey",
			metaTags: [
				{
					name: "description",
					content:
						"Information about our company. FreeTaxUSA data for users. Find out why we support all the income tax, rebate, refund, deduction, and credit you need."
				},
				{
					name: "keywords",
					content:
						"tax support income deduction refund supporting info about company federal efile returns"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/freefile2024/terms",
		name: "freefile2024/Terms",
		component: () => import("../views/freefile2024/Terms"),
		props: true,
		meta: {
			title: "Terms of Use",
			canonical: "https://www.freetaxusa.com/freefile2024/terms",
			metaTags: [
				{
					name: "description",
					content:
						"These Terms and Conditions of Use apply to the FreeTaxUSA website. By using the site, you agree to these terms of use."
				},
				{
					name: "keywords",
					content: "terms,conditions,use,service"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	//    state pages
	{
		path: "/states",
		name: "states/Home",
		component: () => import("../views/states/Home"),
		props: true,
		meta: {
			title:
				title +
				"  " +
				appYear +
				" State Taxes - Availability and E-File Support",
			canonical: "https://www.freetaxusa.com/states",
			metaTags: [
				{
					name: "description",
					content:
						"State tax software availability for " +
						appYear +
						" tax filing. State e-file release dates."
				},
				{
					name: "keywords",
					content:
						"state, tax, return, filing, online, e-file, e-filing, income"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/alabama",
		name: "Alabama",
		component: () => import("../views/states/Alabama"),
		props: true,
		meta: {
			title:
				"Alabama State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/alabama",
			metaTags: [
				{
					name: "description",
					content:
						"File your Alabama income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Alabama,AL,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/arizona",
		name: "Arizona",
		component: () => import("../views/states/Arizona"),
		props: true,
		meta: {
			title:
				"Arizona State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/arizona",
			metaTags: [
				{
					name: "description",
					content:
						"File your Arizona income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Arizona,AZ,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/arkansas",
		name: "Arkansas",
		component: () => import("../views/states/Arkansas"),
		props: true,
		meta: {
			title:
				"Arkansas State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/arkansas",
			metaTags: [
				{
					name: "description",
					content:
						"File your Arkansas income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Arkansas,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/california",
		name: "California",
		component: () => import("../views/states/California"),
		props: true,
		meta: {
			title:
				"California State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/california",
			metaTags: [
				{
					name: "description",
					content:
						"File your California income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "California,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/colorado",
		name: "Colorado",
		component: () => import("../views/states/Colorado"),
		props: true,
		meta: {
			title:
				"Colorado State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/colorado",
			metaTags: [
				{
					name: "description",
					content:
						"File your Colorado income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Colorado,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/connecticut",
		name: "Connecticut",
		component: () => import("../views/states/Connecticut"),
		props: true,
		meta: {
			title:
				"Connecticut State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/connecticut",
			metaTags: [
				{
					name: "description",
					content:
						"File your Connecticut income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Connecticut,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/delaware",
		name: "Delaware",
		component: () => import("../views/states/Delaware"),
		props: true,
		meta: {
			title:
				"Delaware State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/delaware",
			metaTags: [
				{
					name: "description",
					content:
						"File your Delaware income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Delaware,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/district-of-columbia",
		name: "DistrictOfColumbia",
		component: () => import("../views/states/DistrictOfColumbia"),
		props: true,
		meta: {
			title:
				"District of Columbia State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/district-of-columbia",
			metaTags: [
				{
					name: "description",
					content:
						"File your District of Columbia income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "District of Columbia,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/georgia",
		name: "Georgia",
		component: () => import("../views/states/Georgia"),
		props: true,
		meta: {
			title:
				"Georgia State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/georgia",
			metaTags: [
				{
					name: "description",
					content:
						"File your Georgia income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Georgia,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/hawaii",
		name: "Hawaii",
		component: () => import("../views/states/Hawaii"),
		props: true,
		meta: {
			title: "Hawaii State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/hawaii",
			metaTags: [
				{
					name: "description",
					content:
						"File your Hawaii income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Hawaii,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/idaho",
		name: "Idaho",
		component: () => import("../views/states/Idaho"),
		props: true,
		meta: {
			title: "Idaho State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/idaho",
			metaTags: [
				{
					name: "description",
					content:
						"File your Idaho income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Idaho,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/illinois",
		name: "Illinois",
		component: () => import("../views/states/Illinois"),
		props: true,
		meta: {
			title:
				"Illinois State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/illinois",
			metaTags: [
				{
					name: "description",
					content:
						"File your Illinois income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Illinois,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/indiana",
		name: "Indiana",
		component: () => import("../views/states/Indiana"),
		props: true,
		meta: {
			title:
				"Indiana State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/indiana",
			metaTags: [
				{
					name: "description",
					content:
						"File your Indiana income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Indiana,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/iowa",
		name: "Iowa",
		component: () => import("../views/states/Iowa"),
		props: true,
		meta: {
			title: "Iowa State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/iowa",
			metaTags: [
				{
					name: "description",
					content:
						"File your Iowa income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Iowa,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/kansas",
		name: "Kansas",
		component: () => import("../views/states/Kansas"),
		props: true,
		meta: {
			title: "Kansas State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/kansas",
			metaTags: [
				{
					name: "description",
					content:
						"File your Kansas income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Kansas,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/kentucky",
		name: "Kentucky",
		component: () => import("../views/states/Kentucky"),
		props: true,
		meta: {
			title:
				"Kentucky State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/kentucky",
			metaTags: [
				{
					name: "description",
					content:
						"File your Kentucky income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Kentucky,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/louisiana",
		name: "Louisiana",
		component: () => import("../views/states/Louisiana"),
		props: true,
		meta: {
			title:
				"Louisiana State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/louisiana",
			metaTags: [
				{
					name: "description",
					content:
						"File your Louisiana income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Louisiana,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/maine",
		name: "Maine",
		component: () => import("../views/states/Maine"),
		props: true,
		meta: {
			title: "Maine State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/maine",
			metaTags: [
				{
					name: "description",
					content:
						"File your Maine income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Maine,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/maryland",
		name: "Maryland",
		component: () => import("../views/states/Maryland"),
		props: true,
		meta: {
			title:
				"Maryland State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/maryland",
			metaTags: [
				{
					name: "description",
					content:
						"File your Maryland income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Maryland,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/massachusetts",
		name: "Massachusetts",
		component: () => import("../views/states/Massachusetts"),
		props: true,
		meta: {
			title:
				"Massachusetts State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/massachusetts",
			metaTags: [
				{
					name: "description",
					content:
						"File your Massachusetts income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Massachusetts,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/michigan",
		name: "Michigan",
		component: () => import("../views/states/Michigan"),
		props: true,
		meta: {
			title:
				"Michigan State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/michigan",
			metaTags: [
				{
					name: "description",
					content:
						"File your Michigan income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Michigan,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/minnesota",
		name: "Minnesota",
		component: () => import("../views/states/Minnesota"),
		props: true,
		meta: {
			title:
				"Minnesota State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/minnesota",
			metaTags: [
				{
					name: "description",
					content:
						"File your Minnesota income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Minnesota,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/mississippi",
		name: "Mississippi",
		component: () => import("../views/states/Mississippi"),
		props: true,
		meta: {
			title:
				"Mississippi State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/mississippi",
			metaTags: [
				{
					name: "description",
					content:
						"File your Mississippi income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Mississippi,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/missouri",
		name: "Missouri",
		component: () => import("../views/states/Missouri"),
		props: true,
		meta: {
			title:
				"Missouri State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/missouri",
			metaTags: [
				{
					name: "description",
					content:
						"File your Missouri income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Missouri,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/montana",
		name: "Montana",
		component: () => import("../views/states/Montana"),
		props: true,
		meta: {
			title:
				"Montana State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/montana",
			metaTags: [
				{
					name: "description",
					content:
						"File your Montana income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Montana,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/nebraska",
		name: "Nebraska",
		component: () => import("../views/states/Nebraska"),
		props: true,
		meta: {
			title:
				"Nebraska State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/nebraska",
			metaTags: [
				{
					name: "description",
					content:
						"File your Nebraska income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Nebraska,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/new-jersey",
		name: "NewJersey",
		component: () => import("../views/states/NewJersey"),
		props: true,
		meta: {
			title:
				"New Jersey State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/new-jersey",
			metaTags: [
				{
					name: "description",
					content:
						"File your New Jersey income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "New Jersey,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/new-mexico",
		name: "NewMexico",
		component: () => import("../views/states/NewMexico"),
		props: true,
		meta: {
			title:
				"New Mexico State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/new-mexico",
			metaTags: [
				{
					name: "description",
					content:
						"File your New Mexico income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "New Mexico,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/new-york",
		name: "NewYork",
		component: () => import("../views/states/NewYork"),
		props: true,
		meta: {
			title:
				"New York State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/new-york",
			metaTags: [
				{
					name: "description",
					content:
						"File your New York income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "New York,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/north-carolina",
		name: "NorthCarolina",
		component: () => import("../views/states/NorthCarolina"),
		props: true,
		meta: {
			title:
				"North Carolina State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/north-carolina",
			metaTags: [
				{
					name: "description",
					content:
						"File your North Carolina income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "North Carolina,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/north-dakota",
		name: "NorthDakota",
		component: () => import("../views/states/NorthDakota"),
		props: true,
		meta: {
			title:
				"North Dakota State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/north-dakota",
			metaTags: [
				{
					name: "description",
					content:
						"File your North Dakota income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "North Dakota,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/ohio",
		name: "Ohio",
		component: () => import("../views/states/Ohio"),
		props: true,
		meta: {
			title: "Ohio State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/ohio",
			metaTags: [
				{
					name: "description",
					content:
						"File your Ohio income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Ohio,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/oklahoma",
		name: "Oklahoma",
		component: () => import("../views/states/Oklahoma"),
		props: true,
		meta: {
			title:
				"Oklahoma State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/oklahoma",
			metaTags: [
				{
					name: "description",
					content:
						"File your Oklahoma income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Oklahoma,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/oregon",
		name: "Oregon",
		component: () => import("../views/states/Oregon"),
		props: true,
		meta: {
			title: "Oregon State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/oregon",
			metaTags: [
				{
					name: "description",
					content:
						"File your Oregon income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Oregon,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/pennsylvania",
		name: "Pennsylvania",
		component: () => import("../views/states/Pennsylvania"),
		props: true,
		meta: {
			title:
				"Pennsylvania State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/pennsylvania",
			metaTags: [
				{
					name: "description",
					content:
						"File your Pennsylvania income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Pennsylvania,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/rhode-island",
		name: "RhodeIsland",
		component: () => import("../views/states/RhodeIsland"),
		props: true,
		meta: {
			title:
				"Rhode Island State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/rhode-island",
			metaTags: [
				{
					name: "description",
					content:
						"File your Rhode Island income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Rhode Island,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/south-carolina",
		name: "SouthCarolina",
		component: () => import("../views/states/SouthCarolina"),
		props: true,
		meta: {
			title:
				"South Carolina State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/south-carolina",
			metaTags: [
				{
					name: "description",
					content:
						"File your South Carolina income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "South Carolina,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/utah",
		name: "Utah",
		component: () => import("../views/states/Utah"),
		props: true,
		meta: {
			title: "Utah State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/utah",
			metaTags: [
				{
					name: "description",
					content:
						"File your Utah income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Utah,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/vermont",
		name: "Vermont",
		component: () => import("../views/states/Vermont"),
		props: true,
		meta: {
			title:
				"Vermont State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/vermont",
			metaTags: [
				{
					name: "description",
					content:
						"File your Vermont income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Vermont,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/virginia",
		name: "Virginia",
		component: () => import("../views/states/Virginia"),
		props: true,
		meta: {
			title:
				"Virginia State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/virginia",
			metaTags: [
				{
					name: "description",
					content:
						"File your Virginia income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Virginia,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/washington",
		name: "Washington",
		component: () => import("../views/states/Washington"),
		props: true,
		meta: {
			title:
				"Washington State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/washington",
			metaTags: [
				{
					name: "description",
					content:
						"File your Washington income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Washington,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/west-virginia",
		name: "WestVirginia",
		component: () => import("../views/states/WestVirginia"),
		props: true,
		meta: {
			title:
				"West Virginia State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/west-virginia",
			metaTags: [
				{
					name: "description",
					content:
						"File your West Virginia income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "West Virginia,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/wisconsin",
		name: "Wisconsin",
		component: () => import("../views/states/Wisconsin"),
		props: true,
		meta: {
			title:
				"Wisconsin State Tax Software, Preparation, and E-File on FreeTaxUSA",
			canonical: "https://www.freetaxusa.com/states/wisconsin",
			metaTags: [
				{
					name: "description",
					content:
						"File your Wisconsin income tax return. All tax brackets are supported. IRS e-file is included, and federal returns are always free."
				},
				{
					name: "keywords",
					content: "Wisconsin,stateCode,state,taxes,software,forms"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/states/answers/:catchAll(.*)*",
		name: "StateAnswers",
		component: () => import("../views/states/answers/stateAnswers"),
		meta: {
			title: "State Tax Questions and Answers",
			metaTags: [
				{
					name: "description",
					content: "Frequently asked questions for state taxes."
				},
				{
					name: "keywords",
					content: "state,tax,questions,answers,help"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	}
]

export default routes
