import getFutureDate from "@/functions/getFutureDate"

const navButtonABTest = () => {
	const expirationDate = getFutureDate(30)

	if (document.cookie.includes("AB-NavButton2=")) {
		const cookieValue = document.cookie.split("; ").find((row) => row.startsWith("AB-NavButton2="))?.split("=")[1]
		if (cookieValue.includes("WithStartButton")) showVariant()
	}
	else {
		_paq.push(["AbTesting::create", {
			name: "NavButton2",
			percentage: 100,
			includedTargets: [{"attribute": "url", "inverted": "0", "type": "any", "value": ""}],
			excludedTargets: [],
			startDateTime: "2024/09/19 00:00:00 UTC",
			variations: [{
				name: "original", activate: function() {
					document.cookie = "AB-NavButton2=Original;" + expirationDate + ";path=/;secure"
					showOriginal()
				}
			}, {
				name: "Original2", activate: function() {
					document.cookie = "AB-NavButton2=Original2;" + expirationDate + ";path=/;secure"
					showOriginal()
				}
			}, {
				name: "WithStartButton1", activate: function() {
					document.cookie = "AB-NavButton2=WithStartButton1;" + expirationDate + ";path=/;secure"
					showVariant()
				}
			}, {
				name: "WithStartButton2", activate: function() {
					document.cookie = "AB-NavButton2=WithStartButton2;" + expirationDate + ";path=/;secure"
					showVariant()
				}
			}],
			trigger: function() {
				return true
			}
		}])
	}
}

const showOriginal = () => {
	document.getElementById("AB-startButton").classList.remove("d-md-block")
}

const showVariant = () => {
	document.getElementById("AB-startButton").classList.add("d-md-block")
}

export default navButtonABTest
