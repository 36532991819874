const matomo = () => {
	window._paq.push(["setCustomUrl", location.pathname])
	window._paq.push(["setDocumentTitle", location.pathname + " - " + document.title])
	window._paq.push(["setCookieDomain", "*.freetaxusa.com"])
	window._paq.push(["setDomains", ["*.freetaxusa.com"]])
	window._paq.push(["enableCrossDomainLinking"])
	window._paq.push(["enableLinkTracking"])
	window._paq.push(["trackPageView"])
}

export default matomo
